import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CDBSidebar, CDBSidebarContent, CDBSidebarFooter, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem } from 'cdbreact';

import LoginContext from '../LoginContext';
import { deleteStorage } from './Storage';

import logo from './../assets/art/logo.png';

export default function ClientNavBar(){
  const {login, setLogin} = useContext(LoginContext);

  const logout = () => {
    setLogin({});
    deleteStorage("user");
    window.location.replace("/");
  }

  return(
    <Styles style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <div className="mainsidebar">
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <NavLink to="/" style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={logo} style={{ width: '100%', height: 'auto' }} />
          </NavLink>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table">All Courses</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/mine" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">Booked Courses</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/support" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="comments">Message Support</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/settings" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="tools">Settings</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center', borderTop: "1px solid #555" }}>
          <div onClick={() => logout()} style={{ padding: "20px 5px", cursor: 'pointer' }}>
            Log out
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .mainsidebar{
    position: -webkit-sticky;
    position: sticky;
    top: 0vh;
    height: 100vh;
  }

`;