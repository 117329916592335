import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { DatePicker, TimePicker, Select } from 'antd';
import axios from 'axios';
import { Alert } from 'rsuite';

import TimeParser from '../../components/TimeParser';

import { url } from '../../components/Url';
import close from './../../assets/art/close.png';

export default function AddTraining(){
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});
  const [sdate, setSdate] = useState(null);
  const [desc, setDesc] = useState('');
  const [sessions, setSessions] = useState([]);
  const [day, setDay] = useState("Mondays");
  const [time, setTime] = useState(null);
  const [trainer, setTrainer] = useState('');
  const [bio, setBio] = useState('');
  const [img, setImg] = useState('');
  const [pic, setPic] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.post(url()+"admin/courses.php")
    .then(res => setCourses(res.data.courses));
  }, []);

  const scrollTo = async(id) => {
    var elmnt = document.getElementById(id);
    elmnt.focus();
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  function Diagnosis(){
    if(!course.title){
      scrollTo('course');
      return Alert.error("Please select A course");
    }
    if(!sdate){
      scrollTo('sdate');
      return Alert.error("Please select a start date");
    }
    if(!desc){
      scrollTo('desc');
      return Alert.error("Please provide a description");
    }
    if(sessions.length < 1){
      scrollTo('addbtn');
      return Alert.error("Add at least one session");
    }
    if(!trainer){
      scrollTo('trainer');
      return Alert.error("Add a trainer");
    }
    if(!bio){
      scrollTo('bio');
      return Alert.error("Add trainer bio");
    }
    if(!pic){
      scrollTo('picbtn');
      return Alert.error("Add a trainer photograph");
    }
    if(!loading){
      setLoading(true);
      uploadTraining();
    }
  }

  function uploadTraining(){
    const date = new Date(sdate);
    const datestr = date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate();
    const data = new FormData();
    data.append('course', course.id);
    data.append('sdate', datestr);
    data.append('desc', desc);
    data.append('sessions', JSON.stringify(sessions));
    data.append('trainer', trainer);
    data.append('bio', bio);
    data.append('pic', pic);

    fetch(url()+"admin/add_training.php", {
      method: 'POST',
      body: data,
      "Content-Type": 'multipart/form-data'
    })
    .then((response) => response.json())
    .then((res) => {
      if(res.stat == 1){
        Alert.success("Training Added");
        setCourse({});
        setSdate(null);
        setDesc('');
        setSessions([]);
      }
    });
    setLoading(false);
  }

  const selectCourse = (e) => {
    setCourse(e);
    setShow(false);
  }

  const addSession = () => {
    if(day && time){
      const session = { day: day, time: time };
      setSessions([...sessions, session]);
      setTime(null);
      setDay("Mondays");
    }else{
      Alert.error("Select a session time")
    }
  }

  const removeSession = (index) => {
    setSessions(
      sessions.filter((i, j) => j != index)
    );
  }

  const addPicture = (e) => {
    if(e.target.files[0]){
      setImg(URL.createObjectURL(e.target.files[0]));
      setPic(e.target.files[0]);
    }
  }

  const courseModal = () => {
    return(
      <Modal size="md" backdrop="static" style={{ color: '#424242', backgroundColor: 'rgba(0,0,0,0.7)' }} show={show} onHide={() => setShow(false)} >
        <img onClick={() => setShow(false)} src={close} style={{ alignSelf: 'flex-end', width: 15, height: 15, margin: 2, marginBottom: 0, cursor: 'pointer' }} />
        <div>
          {courses.map(i => {
            return(
              <div onClick={() => selectCourse(i)} className="onecourse" style={{ display: 'inline-block', width: '40%', height: 70, backgroundColor: 'skyblue', margin: 10, borderRadius: 10, backgroundImage: `url(${url()+i.pic})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', cursor: 'pointer' }}>
                <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', borderRadius: 10 }}>
                  <b style={{ textAlign: 'center' }}>{i.title}</b>
                </div>
              </div>
            )
          })}
        </div>
      </Modal>
    )
  }

  return(
    <Styles>
      <Container style={{ paddingTop: 30, paddingBottom: 100 }}>
        <center style={{ marginBottom: 30 }}>
          <h3 className="h3">Add Training</h3>
        </center>
        <Row className="formrow">
          <Col sm="12" md="3" className="formlabel">Course</Col>
          <Col sm="12" md="8" className="content">
            {!course.title && <button id="course" onClick={() => setShow(true)} className="selectbtn">Select Course</button>}
            {course.title && 
            <div onClick={() => setShow(true)} style={{ color: '#05b1fb', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', maxWidth: 400 }}>
              <p style={{ margin: 0, padding: 0 }}>{course.title}</p>
              <p className="small" style={{ margin: 0, padding: 0, color: '#aaaaaa', cursor: 'pointer' }}>Change</p>
            </div>}
          </Col>
        </Row>

        <Row className="formrow">
          <Col sm="12" md="3" className="formlabel">Start Date</Col>
          <Col sm="12" md="8" className="content"><DatePicker id="sdate" value={sdate} onChange={e => setSdate(e)} className="inputtxt" /></Col>
        </Row>

        <Row className="formrow">
          <Col sm="12" md="3" className="formlabel" style={{ alignSelf: 'flex-start' }}>Description</Col>
          <Col sm="12" md="8" className="content"><textarea id="desc" value={desc} onChange={e => setDesc(e.target.value)} className="inputtxt" placeholder="Description" style={{ minHeight: 100 }} ></textarea></Col>
        </Row>

        <Row className="formrow">
          <Col sm="12" md="3" className="formlabel" style={{ alignSelf: 'flex-start' }}>Sessions</Col>
          <Col sm="12" md="8" className="content">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', maxWidth: 400 }}>
              <Select value={day} onChange={e => setDay(e)} className="inputtime" type="text">
                <option value="Mondays">Mondays</option>
                <option value="Tuesdays">Tuesdays</option>
                <option value="Wednesdays">Wednesdays</option>
                <option value="Thursdays">Thursdays</option>
                <option value="Fridays">Fridays</option>
                <option value="Saturdays">Saturdays</option>
                <option value="Sundays">Sundays</option>
              </Select>
              <TimePicker format={"h:mma"} use12Hours value={time} onChange={e => setTime(e)} style={{ width: '48%' }} />
              <button id="addbtn" onClick={() => addSession()} className="addbtn">Add</button>
            </div>
            <div style={{ width: 400, maxWidth: '100%', paddingBottom: 10 }}>
              {sessions.map((i, j) => {
                return(
                  <div style={{ fontSize: 13, marginTop: 3, display: 'flex' }}>
                    <span style={{textTransform: 'uppercase', marginRight: 5}}>{i.day+" at "}</span>
                    <TimeParser time={i.time} />
                    <button style={{ marginLeft: 30, width: 18, height: 18, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%' }} onClick={() => removeSession(j)}>x</button>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>

        <Row className="formrow">
          <Col sm="12" md="3" className="formlabel">Trainer</Col>
          <Col sm="12" md="8" className="content"><input id="trainer" value={trainer} onChange={e => setTrainer(e.target.value)} type="text" className="inputtxt" placeholder="Trainer's Name" /></Col>
        </Row>

        <Row className="formrow">
          <Col sm="12" md="3" className="formlabel" style={{ alignSelf: 'flex-start' }}>Bio</Col>
          <Col sm="12" md="8" className="content"><textarea id="bio" value={bio} onChange={e => setBio(e.target.value)} className="inputtxt" placeholder="Trainer's Bio" style={{ minHeight: 100 }} ></textarea></Col>
        </Row>

        <Row className="formrow">
          <Col sm="12" md="3" className="formlabel" style={{ alignSelf: 'flex-start' }}>Photo <input accept="image/*" id="pic" style={{ display: 'none' }} type="file" onChange={e => addPicture(e)} /></Col>
          <Col sm="12" md="8" className="content">
            {pic && 
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', maxWidth: 400 }}>
                <label style={{ cursor: 'pointer'}} for="pic">
                  <img src={img} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                </label>
                <label style={{ cursor: 'pointer', alignSelf: 'flex-end' }} for="pic">
                  <p className="small" style={{ margin: 0, padding: 0, color: '#aaaaaa', }}>Change</p>
                </label>
              </div>
            }
            {!pic && <label style={{ cursor: 'pointer', width: '100%' }} id="picbtn" for="pic"><span className="selectbtn">Select Trainer's Photo</span></label>}
          </Col>
        </Row>

        <Row className="formrow">
          <Col sm="12" md="3" className="formlabel" style={{ alignSelf: 'flex-start' }}></Col>
          <Col sm="12" md="8" className="content">
            <button onClick={() => Diagnosis()} className="submitbtn">Submit Training</button>
          </Col>
        </Row>
        
      </Container>
      {courseModal()}
    </Styles>
  )
}

const Styles = styled.div`
  .formrow{
    width: 100%;
    margin: 0px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .formlabel{
    display: flex;
    justify-content: flex-end;
    color: #05b1fb;
  }

  .content{
    background: ;
  }

  .inputtxt{
    width: 100%;
    max-width: 400px;
    border: 1px solid #dadada;
    padding: 4px;
    padding-left: 6px;
    border-radius: 2px;
    outline: none;

    &: hover{
      border: 1px solid #05b1fb;
    }

    &: focus{
      border: 1px solid #05b1fb;
    }
  }

  .selectbtn{
    background-color: transparent;
    padding: 6px;
    outline: none;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;

    &: hover{
      color: #05b1fb;
      box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1);
    }
  }

  .inputtime{
    width: 100px;
    max-width: 28%;
    border-radius: 1px;
    outline: none;
  }

  .submitbtn{
    background-color: #049fe1;
    color: white;
    width: 100%;
    max-width: 400px;
    border-radius: 2px;
    margin-top: 20px;
    padding: 3px;
    outline: none;

    &: hover{
      background-color: #05b1fb;
    }
  }

  @media screen and (max-width: 770px){
    .formlabel{
      display: block;
    }
  }

`;