import React from 'react';

export default function TimeParser(props){
  const d = new Date(props.time);
  const hr = d.getHours();
  var min = d.getMinutes();
  if(min < 10){
    min = "0"+min
  }
  const datestr = (hr > 12) ? (hr - 12+":"+min+"pm") : (hr+":"+min+"am");
  return datestr;
}