import React, { useEffect, useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Col, Row, Carousel, Modal } from 'react-bootstrap';
import axios from 'axios';
import { Alert } from 'rsuite';

import { setInstorage } from '../components/Storage';
import { url } from './../components/Url';

import GetReviews from '../components/GetReviews';
import LoginContext from '../LoginContext';

import homeimg from "./../assets/img/21.jpg";
import img20 from "./../assets/img/20.jpg";
import img4 from "./../assets/img/4.jpg";
import library from "./../assets/img/11.jpg";
import mathimg from "./../assets/img/1.jpg";
import prepimg from "./../assets/img/14.jpg";
import assistimg from "./../assets/img/12.jpg";
import satimg from "./../assets/img/10.jpg";
import helpimg from "./../assets/img/2.jpg";
import vimg from './../assets/img/7.jpg';
import simg from './../assets/img/13.jpg';
import modimg from './../assets/img/3.jpg';

import close from './../assets/art/close.png';
import book from "./../assets/art/book.png";
import math from "./../assets/art/math.png";
import clock from "./../assets/art/clock.png";

import './base.css';

export default function Home(){
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [enrol, setEnrol] = useState(false);
  const {login, setLogin} = useContext(LoginContext);

  function enrolUser(){
    if(!name || !phone || !email || !password){
      return Alert.error("Please fill all fields");
    }
    const data = { name: name, phone: phone, email: email, password: password }
    axios.post(url()+"signup.php", data)
    .then(res => {
      if(res.data.stat == 1){
        const data = { name: name, userid: res.data.userid };
        setInstorage("user", data);
        setLogin(data);
        history.push("/");
      }else{
        Alert.error("Could not enroll, client exist already");
      }
    })
  }

  const enrolModal = () => {
    const style = {
      inputtxt: {
        width: '100%',
        height: '40px',
        padding: "5px 15px",
        borderRadius: "20px",
        border: "1px solid #ababab",
        marginBottom: 10,
        outline: 'none'
      },
      btn: {
        width: '100%',
        height: '35px',
        borderRadius: "20px",
        border: "1px solid #ababab",
        outline: 'none'
      }
    }
    return(
      <Modal size="lg" style={{ color: '#424242', }} backdrop={"static"} show={enrol} onClose={() => setShow(true)}>
        <Row>
          <Col md="5" className="d-none d-md-block" style={{ height: 400, backgroundImage: `url(${modimg})`, padding: 0, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }}>
            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(66, 172, 224,0.7)', borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }}></div>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <img onClick={() => setEnrol(false)} src={close} style={{ alignSelf: 'flex-end', width: 15, height: 15, margin: 5, cursor: 'pointer' }} />
            <div style={{ width: '100%', padding: 15 }}>
              <h4 className="h4">Welcome</h4>
              <p>Please fill out this form to help us setup your profile.</p>
              <Container>
                <input style={style.inputtxt} type="text" placeholder="Full Name" onChange={e => setName(e.target.value)} />
                <input style={style.inputtxt} type="text" placeholder="Phone" onChange={e => setPhone(e.target.value)} />
                <input style={style.inputtxt} type="text" placeholder="Email Address" onChange={e => setEmail(e.target.value)} />
                <input style={style.inputtxt} type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                <button onClick={() => enrolUser()} style={style.btn} className="btn-primary" >Enroll Now</button>
              </Container>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  useEffect(() => {
    setTimeout(() => scrollToTop(), 0.1*1000)
  }, []);

  const scrollToTop = () => {
    const elmnt = document.getElementById("top");
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  return(
    <Styles style={{ overflowX: 'hidden', marginTop: -50 }}>
      <div id="top" style={{ position: 'absolute', top: -50, width: 1, height: 1 }} />
      <Carousel pause={false} fade={true} slide={true} controls={false}  style={{ width: '100%', height: '92vh', maxHeight: 550 }}>
        <Carousel.Item interval={3500} style={{ width: '100%', height: '92vh', maxHeight: 550 }}>
          <div className="backimg" style={{ backgroundImage: "url("+homeimg+")"}}>S
            <div className="introsec">
              <h1 style={{ color: 'white' }}>We put your child's Education First</h1>
              <div style={{ display: 'flex', fexDirection: 'row', width: '100%', marginTop: 10 }}>
                <button style={{ backgroundColor: '#e1c742' }} className="headbtn" onClick={() => setEnrol(true)}>Enroll Now</button>
                <button style={{ backgroundColor: '#049fe1' }} className="headbtn" onClick={() => history.push('/contact')}>Contact Us</button>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={3500} style={{ width: '100%', height: '92vh', maxHeight: 550 }}>
          <div className="backimg" style={{ backgroundImage: "url("+img20+")"}}>
            <div className="introsec">
              <h4 className="h4" style={{ color: 'white' }}>Aspiring Kids Learning Society provides K-12 educational resources, catering to each student's unique needs.</h4>
              <div style={{ display: 'flex', fexDirection: 'row', width: '100%', marginTop: 10 }}>
                <button style={{ backgroundColor: '#e1c742' }} className="headbtn" onClick={() => setEnrol(true)}>Enroll Now</button>
                <button style={{ backgroundColor: '#049fe1' }} className="headbtn" onClick={() => history.push('/contact')}>Contact Us</button>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={3500} style={{ width: '100%', height: '92vh', maxHeight: 550 }}>
          <div className="backimg" style={{ backgroundImage: "url("+img4+")"}}>
            <div className="introsec">
              <h4 className="h4" style={{ color: 'white' }}>Enroll your child today or contact us for more information.</h4>
              <div style={{ display: 'flex', fexDirection: 'row', width: '100%', marginTop: 10 }}>
                <button style={{ backgroundColor: '#e1c742' }} className="headbtn" onClick={() => setEnrol(true)}>Enroll Now</button>
                <button style={{ backgroundColor: '#049fe1' }} className="headbtn" onClick={() => history.push('/contact')}>Contact Us</button>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
      <Container style={{ textAlign: 'center', paddingTop: 25, width: '100%' }}>
        <h2 className="h1 primary">We prepare our kids to be great</h2>
        <p style={{ marginTop: 20 }}>We pride ourselves on relationship building, individualized learning, quality instruction, and affordability. Aspiring Kids Learning Society supports students in grades K-12 with reading, writing, and math tutoring instruction, academic enrichment, and so much more!</p>
      </Container>

<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Row className="bigrow" style={{ justifyContent: 'space-evenly', flexDirection: 'row', display: 'flex', color: 'white', marginBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
            <Col sm="12" md="5" style={{ backgroundImage: "url("+library+")", height: 250, backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
              <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <img src={book} />
                <h1 className="h1">K-12 Reading Writing Tutoring</h1>
              </div>
            </Col>
            <Col sm="12" md="5" style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }} >
              <Row className="smallrow" style={{ justifyContent: 'space-between' }}>
                <Col className="smaller" sm="6" style={{ backgroundImage: "url("+mathimg+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 0, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <img src={math} style={{ width: 40, height: 40, marginBottom: 7 }} />
                    <h1 className="h6">K-12 Math Tutoring</h1>
                  </div>
                </Col>
                <Col className="smaller" sm="5" style={{ backgroundImage: "url("+prepimg+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 20, 147, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <img src={clock} style={{ width: 40, height: 40, marginBottom: 7 }} />
                    <h1 className="h6">FSA Preparation</h1>
                  </div>
                </Col>
              </Row>
              <Row  className="smallrow" style={{ justifyContent: 'space-between'  }}>
                <Col className="smaller" sm="6" style={{ backgroundImage: "url("+assistimg+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0, 128, 0, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <h1 className="h6">Homework Assistance</h1>
                  </div>
                </Col>
                <Col className="smaller" sm="5" style={{ backgroundImage: "url("+satimg+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(128, 0, 128, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <h1 className="h6">ACT/SAT Preparation</h1>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          </div>

      <Row style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', padding: 15 }}>
        <button onClick={() => history.push('/services')} className="learnbtn">Learn More</button>
      </Row>
      <Row style={{ padding: 0, width: '100%', margin: 0 }}>
        <Col sm="12" md="6" style={{ padding: 0 }}>
          <img src={helpimg} style={{ width: '100%', minHeight: 350, maxHeight: 350, objectFit: 'cover' }} />
        </Col>
        <Col sm="12" md="6" style={{ backgroundColor: '#42ace1', color: 'white', padding: 20 }}>
          <h3 className="h2" style={{ fontFamily: 'sans-serif' }}>We put your child's education first</h3>
          <p style={{ marginTop: 20, marginBottom: 20 }}>
            “Every child deserves a champion; an adult who will never give up on them, who
            understands the power of connection, and insists that they become the best they can
            possibly be.” - Rita F. Pierson, Educator
          </p>
        </Col>
      </Row>

      <Container style={{ marginTop: 20, marginBottom: 20 }}>
        <GetReviews />
      </Container>

      <Row style={{ width: '100%', borderTop: "1px solid #dbdbdb", borderBottom: "1px solid #dbdbdb", paddingTop: 20, paddingBottom: 20, justifyContent: 'center' }}>
        <button onClick={() => history.push("/reviews")} className="learnbtn">Leave a review</button>
      </Row>

      <Row style={{ marginBottom: 10, paddingBottom: 0, borderBottom: '1px solid #dbdbdb' }}>
        <Col sm="11" md="5">
          <img src={vimg} style={{ width: '100%', maxHeight: 300, objectFit: 'cover' }} />
        </Col>
        <Col sm="11" md="6" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: "15px 5%" }}>
          <h3 className="h3" style={{ color: '#42ace1' }}>Certified Professionals</h3>
          <p className="textcontent">Our staff is comprised of educators who have K-6 teaching certifications, 6-12 ELA teaching
            certifications, and/or 6-12 Math teaching certifications. Click <Link to="/team">here</Link> to meet our team.
          </p>
          <button onClick={() => history.push("/team")} className="lbtn">Learn More</button>
        </Col>
      </Row>

      <Row style={{ justifyContent: 'space-between', marginTop: -10 }}>
        <Col sm="11" md="7" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: "15px 5%", margin: 0 }}>
          <h3 className="h3" style={{ color: '#42ace1' }}>Variety of K-12 Support</h3>
          <p className="textcontent">Aspiring Kids Learning Society exists to help your student succeed. We offer a variety of K-12
            educational support, in reading, writing, and mathematics, designed to meet each student at their
            level and help them achieve their academic goals. Click <Link to="/services">here</Link> to look over our K-12
            support services before scheduling your free consultation.
          </p>
          <button onClick={() => history.push("/services")} className="lbtn">Learn More</button>
        </Col>
        <Col sm="11" md="5">
          <img src={simg} style={{ width: '100%', minHeight: 300, maxHeight: 300, objectFit: 'cover' }} />
        </Col>
      </Row>
      {enrolModal()}
    </Styles>
  )
}

const Styles = styled.div`
  .backimg{
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .primary{
    color: #42ace1;
  }

  .introsec{
    width: 70%; 
    margin-top: 13%; 
    padding-left: 40px;
    color: #049fe1;
  }

  .headbtn{
    padding: 4px 15px;
    border-radius: 20px;
    margin-right: 15px;
    color: white;
    outline: none;

    &: hover{
      opacity: 0.8;
    }
  }

  .learnbtn{
    min-width: 120px;
    color: white;
    background-color: #42ace1;
    border-radius: 20px;
    height: 35px;
    outline: none;

    &: hover{
      opacity: 0.7;
    }
  }

  .lbtn{
    width: 130px;
    color: white;
    background-color: #bc023a;
    padding: 7px;
    border-radius: 20px;
  }

  .bigrow{
    width: 80%;
    align-self: center;
  }

  .smallrow{
    height: 120px;
  }

  @media screen and (max-width: 770px){
    .introsec{
      width: 90%;
      margin-top: 10%; 
      padding-left: 40px; 
    }
    
    .bigrow{
      width: 95%;
    }

    .smaller{
      height: 120px;
      margin-top: 10px;
    }

    .smallrow{
      height: fit-content;
    }

    .textcontent{
      padding: 10px;
      padding-top: 0px;
    }

    .learnbtn{
      margin-left: 10px;
    }
  }

`;