import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import styled from "styled-components";
import axios from 'axios';

import { url } from "../../components/Url";
import close from './../../assets/art/close.png';

import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/en';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(frenchStrings);

export default function Users(){
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const colors = ['#42ace1', '#d300ff', '#ff0059', 'orange', '#00ff2d', '#5aff00', '#b1b0b1', '#bb023b'];

  useEffect(() => {
    axios.post(url()+"admin/users.php")
    .then(res => {
      setUsers(res.data.users);
    })
  }, []);

  useEffect(() => {
    setLoading(true)
    axios.post(url()+"admin/user_course.php", { userid: user.id })
    .then(res => {
      if(res.data.courses){
        setCourses(res.data.courses);
      }
    })
    setLoading(false);
  }, [user]);

  const selectOne = (one) => {
    setUser(one);
    setShow(true);
  }

  const userModal = () => {
    const styles = {
      userbox: {
        margin: 10,
        borderRadius: 5,
        height: '40vh',
        maxHeight: 200
      },
    
      user: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%'
      },
    
      img: {
        width: 150,
        height: 150
      },
    
      pic: {
        width: 130,
        height: 130,
        objectFit: 'cover',
        borderRadius: '50%',
        margin: 10,
      },
    
      userinfo: {
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        marginTop: 5,
        marginLeft: 5
      }
    }

    if(user.name)
    return(
      <Modal size="md" backdrop="static" style={{ color: '#424242', backgroundColor: 'rgba(0,0,0,0.7)' }} show={show} onHide={() => setShow(false)} >
        <img onClick={() => setShow(false)} src={close} style={{ alignSelf: 'flex-end', width: 15, height: 15, margin: 5, marginBottom: 0, cursor: 'pointer' }} />
        <div style={styles.userbox}>
          <div style={styles.user}>
            <div style={styles.img}>
              {user.pic && <img src={url()+user.pic} style={styles.pic} />}
              {!user.pic && <div style={{ fontSize: 30, width: 130, height: 130, margin: 10, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: colors[Math.floor(Math.random() * 8)] }} >{user.name[0]}</div>}
            </div>
            <div style={styles.userinfo}>
              <b>{user.name}</b>
              <p className="oneline small">{user.email}</p>
              <p style={{ margin: 0, padding: 0 }}><b>Tel: </b>{user.phone}</p>
              {(user.address && user.address !== 'null') && <p style={{ margin: 0, padding: 0 }}><b>Address: </b>{user.address}</p>}
              <p style={{ margin: 0, marginTop: 5, padding: 0, fontSize: 13 }}>Registered{" "}
                <TimeAgo 
                  formatter={formatter}
                  date={JSON.stringify(user.timestamp)}
                /></p>
            </div>
          </div>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Course</th>
              <th>Trainer</th>
            </tr>
            {courses.map(i => {
              return(
                <tr>
                  <td>{i.title}</td>
                  <td>{i.trainer}</td>
                </tr>
              )
            })}
            {courses.length < 1 && <b><center>No courses yet</center></b>}
          </thead>
        </table>
      </Modal>
    )
  }

  return(
    <Styles>
      <Container style={{ paddingTop: 25, backgroundColor: '#eeeeee', minHeight: '100vh' }}>
        <h5 className="h5" style={{ marginLeft: 30, marginBottom: 15 }}>Registered Users</h5>
        <div className="summary">
          {users.map(i => {
            return(
              <div onClick={() => selectOne(i)} className="userbox">
                <div className="user">
                  <div className="img">
                    {(i.pic && i.pic !== 'null') && <img src={url()+i.pic} className="pic" />}
                    {(!i.pic || i.pic === 'null') && <div className="pic" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: colors[Math.floor(Math.random() * 8)] }} >{i.name[0]}</div>}
                  </div>
                  <div className="userinfo">
                    <b>{i.name}</b>
                    <p className="oneline small">{i.email}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
      {userModal()}
    </Styles>
  )
}

const Styles = styled.div`
  .summary{
    width: 100%;
    padding-left: 25px;
    display: inline-block;
  }

  .userbox{
    width: 230px;
    height: 80px;
    background-color: white;
    margin: 10px;
    display: inline-block;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1);
    border-radius: 5px;
    cursor: pointer;

    &: hover{
      box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.4);
    }
  }

  .user{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .img{
    width: 70px;
    height: 100%;
  }

  .pic{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin: 5px;
  }

  .userinfo{
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    margin-top: 5px;
  }

`;