import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Modal, Row, Col, Container } from 'react-bootstrap';
import styled from 'styled-components';
import {useParams} from 'react-router-dom'
import axios from 'axios';
import { url } from '../components/Url';
import { Alert } from 'rsuite';

export default function PasswordRecovery() {

    const [pass, setPass] = useState('');
    const [confirm, setCOnfirm] = useState('');

    let { id, token } = useParams();

    const enrolUser = ()=>{
        if(!pass || !confirm){
            return Alert.error("All fields are required");
        }
        if(pass !== confirm){
            return Alert.error("Enter same password");
        }
        const data = {
            pass: pass,
            userid: id,
            token: token
        }
        axios.post(url()+"changepass.php", data, {withCredentials: false, headers: {
            'Content-Type': 'application/json'
        }})
        .then(res => {
            console.log(res.data);
            if(res.data.stat === 1){
                return Alert.success("Password changed successfully");
            }else{
                return Alert.error("Error changing password. Try again later");
            }
        }).catch(err => {
            console.log(err);
        })
    }

    // useEffect(()=>{
        
    // }, [])

    const style = {
        inputtxt: {
          width: '300px',
          height: '40px',
          padding: "5px 15px",
          borderRadius: "20px",
          border: "1px solid #ababab",
          marginBottom: 10,
          outline: 'none'
        },
        btn: {
          width: '300px',
          height: '35px',
          borderRadius: "20px",
          border: "1px solid #ababab",
          outline: 'none'
        }
      }
  return (
    <div style={{marginTop: 100, marginBottom: 100}}>
        <div>
            <Container style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <p>Recover your password</p>
                <input style={style.inputtxt} type="password" placeholder="Enter password" onChange={e => setPass(e.target.value)} />
                <input style={style.inputtxt} type="password" placeholder="Confirm password" onChange={e => setCOnfirm(e.target.value)} />
                <button onClick={() => enrolUser()} 
                style={style.btn} className="btn-primary" >Change password</button>
              </Container>
        </div>
    </div>
  )
}
