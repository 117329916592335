import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import tron from './../assets/img/19.jpg';

import simg from './../assets/img/13.jpg';
import p from './../assets/img/p.jpg';
import p1 from './../assets/img/p1.jpg';
import p2 from './../assets/img/p2.jpg';

export default function Team(){
  const history = useHistory();
  const profiles = [
    { pic: p, name: "Mrs. Santrice L. Grayer", role: "Founder & CEO", bio: "A native of Jacksonville, FL, and Duval County Public Schools alumna (graduate of Paxon School for Advanced Studies) - is the founder, owner, and Master Tutor at Aspiring Kids Learning Society. She has had the pleasure of teaching English Language Arts in grades K-12 for the past nine years. Her passion for teaching inspired her to found and grow Aspiring Kids Learning Society." },
    { pic: p1, name: "Mrs. Lena Lyles", role: "Math Teacher & Educator", bio: "Driven by a deep passion for teaching, Mrs. Lyles takes pride in producing the best student achievement possible. As a math teacher, her goals include improving student performance and instilling effective habits in her students, including cultivating sustainable, personal motivation and teaching universal learning skills." },
    { pic: p2, name: "Ms. Wilnekia Childs", role: "Teacher & Instructional Coach", bio: "With over 20 years experience. Driven by data, Ms. Childs takes pride in providing students with engaging, personalized lessons. She is focused on ensuring her students don’t just meet educational standards, but are able to master them, while building confidence in their work." }
  ]

  useEffect(() => {
    setTimeout(() => scrollToTop(), 0.1*1000)
  }, []);

  const scrollToTop = () => {
    const elmnt = document.getElementById("top");
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  return(
    <Styles style={{ marginTop: -50 }}>
      <div id="top" style={{ position: 'absolute', top: -50, width: 1, height: 1 }} />
      <div className="headimg">
        <div className="overlay">
          <Container style={{ padding: '4%' }}>
            <h4 className="h2" style={{ margin: 0 }}>Meet The Team</h4>
            <nav style={{ background: 'transparent', marginLeft: 0, paddingLeft: 0 }} aria-label="breadcrumb">
              <ol style={{ background: 'transparent' }} class="breadcrumb">
                <li class="breadcrumb-item"><Link style={{ color: 'white' }} to="/">Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Meet The Team</li>
              </ol>
            </nav>
          </Container>
        </div>
      </div>
      <center style={{ marginTop: 40, marginBottom: 35, color: '#42ace1' }}><h3 className="h3">Our Team</h3></center>
      <div className="box">
        <Container>
          <p>Our learning center is conveniently located online where all sessions include LIVE instructions and learning for your child! Aspiring Kids was originally located in the historic district of Springfield in Jacksonville, Florida where there is a high need and demand for accessibility to educational resources for undeserved students in the community. However, during the COVID-19 pandemic, we added an online learning platform, to continue serving our students and their families within the community, while expanding our ability to reach students outside of the local area. Our mission is to promote literacy and life skills throughout the various communities of Jacksonville, FL. and beyond. We pride ourselves in relationship building.</p>
        </Container>
      </div>
      <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
        <Col sm="11" md="9">
          {profiles.map(i => {
            return(
              <div className="aprofile" tabIndex="0" style={{ backgroundImage: `url(${i.pic})` }}>
                <div className="big" tabIndex="0">
                  <div className="controller" />
                  <div className="info">
                    <div className="names">
                      <h6 className="h6 oneline">{i.name}</h6>
                      <p className="oneline small">{i.role}</p>
                    </div>
                    <div className="bio">
                      {i.bio}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Col>
      </Row>
      <Row style={{ justifyContent: 'space-between', borderTop: '1px solid #dbdbdb' }}>
        <Col sm="11" md="7" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: "15px 5%" }}>
          <h3 className="h3" style={{ color: '#42ace1' }}>Variety of K-12 Support</h3>
          <p className="textcontent">Aspiring Kids Learning Society exists to help your student succeed. We offer a variety of K-12
            educational support, in reading, writing, and mathematics, designed to meet each student at their
            level and help them achieve their academic goals. Click <Link to="/services">here</Link> to look over our K-12
            support services before scheduling your free consultation.
          </p>
          <button onClick={() => history.push("/services")} className="lbtn">Learn More</button>
        </Col>
        <Col sm="11" md="5">
          <img src={simg} style={{ width: '100%', minHeight: 200, maxHeight: 350, objectFit: 'cover' }} />
        </Col>
      </Row>
    </Styles>
  )
}


const Styles = styled.div`
  .headimg{
    margin-top: 20px;
    width: 100%;
    height: 300px;
    background: url(${tron});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
    color: white;
  }

  .active{
    color: rgba(360,360,360, 0.6);
  }

  .oneline{
    margin: 0px;
    padding: 0px;
  }

  .box{
    background-color: #42ace1;
    color: white;
    padding: 2% 10%;
  }

  .aprofile{
    min-width: 200px;
    width: 30%;
    height: 250px;
    display: inline-block;
    margin-left: 20px;
    margin-bottom: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .big{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;

    &:hover{
      .info{
        margin-top: 0px;
        background-color: rgba(0,0,0,0.8);
      }
    }
  }

  .info{
    background-color: rgba(0,0,0,0.6);
    color: white;
    width: 100%;
    height: 100%;
    margin-top: 70%;
  }

  .names{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .bio{
    padding: 10px;
    font-size: 13px;
    text-align: center;
  }

  @media screen and (max-width: 770px){
    .aprofile{
      width: 90%;
    }

    .info{
      margin-top: 55%;
    }
  }

  .lbtn{
    width: 130px;
    color: white;
    background-color: #bc023a;
    padding: 7px;
    border-radius: 20px;
  }

`;