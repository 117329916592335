import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import GetReviews from '../components/GetReviews';

import tron from './../assets/img/5.jpg';
import library from "./../assets/img/11.jpg";
import mathimg from "./../assets/img/1.jpg";
import prepimg from "./../assets/img/14.jpg";
import assistimg from "./../assets/img/12.jpg";
import satimg from "./../assets/img/10.jpg";
import boyimg from "./../assets/img/7.jpg";
import science from "./../assets/img/science.jpg";

import book from "./../assets/art/book.png";
import math from "./../assets/art/math.png";
import clock from "./../assets/art/clock.png";

export default function Services(){
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => scrollToTop(), 0.1*1000)
  }, []);

  const scrollToTop = () => {
    const elmnt = document.getElementById("top");
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  return(
    <Styles>
      <div id="top" style={{ position: 'absolute', top: -50, width: 1, height: 1 }} />
      <div className="headimg">
        <div className="overlay">
          <Container style={{ padding: '4%' }}>
            <h4 className="h2" style={{ margin: 0 }}>Services</h4>
            <nav style={{ background: 'transparent', marginLeft: 0, paddingLeft: 0 }} aria-label="breadcrumb">
              <ol style={{ background: 'transparent' }} class="breadcrumb">
                <li class="breadcrumb-item"><Link style={{ color: 'white' }} to="/">Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Services</li>
              </ol>
            </nav>
          </Container>
        </div>
      </div>
      <Row style={{ width: '100%', margin: 0, }}>
        <div style={{ width: '', margin: 0, paddingTop: 40, display: 'flex', flexDirection: 'column', width: '100%' }}>
          <h3 className="h3 ml-auto mr-auto" style={{ color: '#42ace1', paddingBottom: 30 }}>Our Services</h3>
          <Row className="bigrow" style={{ justifyContent: 'space-evenly', flexDirection: 'row', display: 'flex', color: 'white', marginBottom: 30 }}>
            <Col sm="12" md="5" style={{ backgroundImage: "url("+library+")", height: 250, backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
              <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <img src={book} />
                <h1 className="h1">K-12 Reading Writing Tutoring</h1>
              </div>
            </Col>
            <Col sm="12" md="5" style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }} >
              <Row className="smallrow" style={{ justifyContent: 'space-between' }}>
                <Col className="smaller" sm="6" style={{ backgroundImage: "url("+mathimg+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 0, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <img src={math} style={{ width: 40, height: 40, marginBottom: 7 }} />
                    <h1 className="h6">K-12 Math Tutoring</h1>
                  </div>
                </Col>
                <Col className="smaller" sm="5" style={{ backgroundImage: "url("+prepimg+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(255, 20, 147, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <img src={clock} style={{ width: 40, height: 40, marginBottom: 7 }} />
                    <h1 className="h6">FSA Preparation</h1>
                  </div>
                </Col>
              </Row>
              <Row  className="smallrow" style={{ justifyContent: 'space-between'  }}>
                <Col className="smaller" sm="6" style={{ backgroundImage: "url("+assistimg+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0, 128, 0, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <h1 className="h6">Homework Assistance</h1>
                  </div>
                </Col>
                <Col className="smaller" sm="5" style={{ backgroundImage: "url("+satimg+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(128, 0, 128, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                    <h1 className="h6">ACT/SAT Preparation</h1>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="bigrow" style={{ justifyContent: 'space-evenly', flexDirection: 'row', display: 'flex', color: 'white', marginBottom: 20 }}>
            <Col sm="12" md="5" style={{ backgroundImage: "url("+library+")", height: 250, backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0, marginBottom: 10 }}>
              <div style={{ width: '100%', height: '100%', backgroundColor: 'RGB(66, 172, 224, 0.7)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'center', paddingBottom: 15 }}>
                <h1 className="h3">College<br/>Career Coaching</h1>
              </div>
            </Col>
            <Col sm="12" md="5" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: 250 }}>
              <Row style={{ height: '45%' }}>
                <Col sm="12" style={{ backgroundImage: "url("+science+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'RGB(99, 00, 30, 0.5)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'center', paddingBottom: 15 }}>
                    <h1 className="h3">K-5 Science Tutoring</h1>
                  </div>
                </Col>
              </Row>
              <Row style={{ height: '45%' }}>
                <Col sm="12" style={{ backgroundImage: "url("+library+")", backgroundPosition: 'center', backgroundSize: 'cover', borderRadius: 10, padding: 0 }}>
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'RGB(66, 172, 224, 0.7)', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'center', paddingBottom: 15 }}>
                    <h1 className="h3">Homeschool<br/>Micro-school support</h1>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Row>

      <div className="box">
        <Container>
          <p>
            All our services are available for grades K-12, and uniquely customized to the learning needs of
            each student. Our learning sessions are by appointment only and conducted in one-hour
            increments, starting at $30 per hour. During the first session, each new student will undergo a
            diagnostic assessment, which is used to create a customized learning plan. Each family has the
            choice to receive academic assistance in reading, writing, or math instruction. Our primary
            curriculum is, but not limited to, Common Core State Standards. We welcome friendly suggestions
            to help make your student feel their best at all times. Visit our <Link style={{ color: 'white', textDecoration: 'underline' }} to="/contact">contact page</Link> to
            connect with us, and receive a free educational consultation, to get your child started today.
          </p>
        </Container>
      </div>

      <Row style={{ width: '100%', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
        <div className="catset">
          <Container>
            <h4>K-12 Academic Tutoring</h4>
            <ul>
              <li>1-on-1 instruction.</li>
              <li>Small group instruction.</li>
              <li>Proven academic interventions and strategies to aid in student mastery.</li>
              <li>Customized learning plans based on your child’s individual needs.</li>
              <li>Engaging lessons with practice on a variety of skills and concepts.</li>
              <li>Progress monitoring.</li>
            </ul>
          </Container>
        </div>
        <div className="catset">
          <Container>
            <h4>K-12 Reading Tutoring (online)</h4>
            <ul>
              <li>1-on-1 instruction.</li>
              <li>Small group instruction.</li>
              <li>Proven academic interventions and strategies to aid in student mastery.</li>
              <li>Customized learning plans based on your child's individual needs.</li>
              <li>Engaging lessons with practice on a variety of skills and concepts.</li>
              <li>Progress monitoring.</li>
            </ul>
          </Container>
        </div>
        <div className="catset">
          <Container>
            <h4>K-12 Math Tutoring (online)</h4>
            <ul>
              <li>1-on-1 instruction.</li>
              <li>Small group instruction.</li>
              <li>Proven academic interventions and strategies to aid in student mastery.</li>
              <li>Customized learning plans based on your child’s individual needs.</li>
              <li>Engaging lessons with practice on a variety of skills and concepts.</li>
              <li>Progress monitoring.</li>
            </ul>
          </Container>
        </div>
        <div className="catset">
          <Container>
            <h4>College/Career Coaching</h4>
            <ul>
              <li>Financial aid guidance.</li>
              <li>College application assistance.</li>
              <li>Scholarship search assistance.</li>
              <li>College major/program of study selection assistance</li>
              <li>Résumé and cover letter review.</li>
              <li>Interview skills and etiquette coaching.</li>
              <li>Job search assistance.</li>
            </ul>
          </Container>
        </div>
        <div className="catset">
          <Container>
            <h4>FSA Preparation</h4>
            <ul>
              <li>Skill and concept reviews with FSA test items.</li>
              <li>Mini lessons.</li>
              <li>Practice FSA assessments.</li>
              <li>FSA test-taking strategies.</li>
              <li>Progress monitoring and feedback.</li>
            </ul>
          </Container>
        </div>
        <div className="catset">
          <Container>
            <h4>Homeschool/Micro-school Support</h4>
            <ul>
              <li>Effective online curriculum.</li>
              <li>Customized learning plans.</li>
              <li>Feedback provided</li>
              <li>Engaging lessons with practice on a variety of skills and concepts.</li>
              <li>Progress monitoring and feedback.</li>
            </ul>
          </Container>
        </div>

        <div className="catset">
          <Container>
            <h4>ACT/SAT Preparation</h4>
            <ul>
              <li>Skills and concepts reviews.</li>
              <li>Preparation in each test domain.</li>
              <li>Practice assessments.</li>
              <li>ACT/SAT test-taking strategies.</li>
            </ul>
          </Container>
        </div>
        
        <div className="catset">
          <Container>
            <h4>Homework Assistance</h4>
            <ul>
              <li>Instructional support with homework questions, assignments, and/or projects</li>
            </ul>
          </Container>
        </div>
      </Row>

      <Container style={{ marginTop: 20, marginBottom: 20 }}>
        <GetReviews />
      </Container>

      <Row style={{ width: '100%', borderTop: "1px solid #dbdbdb", borderBottom: "1px solid #dbdbdb", paddingTop: 20, paddingBottom: 20, justifyContent: 'center', marginBottom: 25 }}>
        <button onClick={() => history.push("/reviews")} className="lbtn">Leave a review</button>
      </Row>

      <Row style={{ borderBottom: '1px solid #dbdbdb', marginTop: -25, marginBottom: 0 }}>
        <Col sm="11" md="5">
          <img src={boyimg} style={{ width: '100%', maxHeight: 300, height: '100%', objectFit: 'cover', marginTop: 0 }} />
        </Col>
        <Col sm="11" md="6" style={{ marginBottom: 25, padding: "15px 5%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <h3 className="h3" style={{ color: '#42ace1' }}>Certified Professionals</h3>
          <p className="textcontent">Our staff is comprised of educators who have K-6 teaching certifications, 6-12 ELA teaching
            certifications, and/or 6-12 Math teaching certifications. Click <Link to="/team">here</Link> to meet our team.
          </p>
          <button onClick={() => history.push("/team")} className="learnbtn">Learn More</button>
        </Col>
      </Row>
    </Styles>
  )
}


const Styles = styled.div`
  .headimg{
    margin-top: 20px;
    width: 100%;
    height: 300px;
    background: url(${tron});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
    color: white;
  }

  .active{
    color: rgba(360,360,360, 0.6);
  }

  .box{
    background-color: #42ace1;
    color: white;
    padding: 2% 10%;
  }

  .bigrow{
    width: 80%;
    align-self: center;
  }

  .smallrow{
    height: 120px;
  }

  .box{
    background-color: #42ace1;
    color: white;
    padding: 2% 10%;
  }

  .catset{
    width: 450px;
    max-width: 100%;
    display: block;
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    &: hover{
      box-shadow: 1px 2px 5px 1px rgba(1,117,216,0.3);
    }
  }

  .catset ul{
    list-style: none;
  }

  .catset li::before {
    content: "~";
    color: #0175d8;
    font-weight: bold;
    width: 40px;
    height: 40px;
    margin-left: -1em;
    margin-right: 5px;
  }

  .catset h4{
    color: #0175d8;
  }

  @media screen and (max-width: 770px){
    .bigrow{
      width: 94%;
      // margin-left: 3%;
    }

    .smaller{
      height: 120px;
      margin-top: 10px;
    }

    .smallrow{
      height: fit-content;
    }

    .textcontent{
      padding: 10px;
      padding-top: 0px;
    }

    .learnbtn{
      margin-left: 10px;
    }
  }

  .learnbtn{
    width: 130px;
    color: white;
    background-color: #bc023a;
    padding: 7px;
    border-radius: 20px;
  }

  .lbtn{
    min-width: 120px;
    color: white;
    background-color: #42ace1;
    border-radius: 20px;
    height: 35px;
    outline: none;

    &: hover{
      opacity: 0.7;
    }
  }

`;