import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Alert } from 'rsuite';

import { url } from '../components/Url';
import { getFromStorage, setInstorage } from '../components/Storage';

import pdf1 from './../docs/1.pdf';
import pdf2 from './../docs/2.pdf';
import pdf3 from './../docs/3.pdf';
import pdf4 from './../docs/4.pdf';

import pdf from './../assets/art/pdf.png';
import download from './../assets/art/download.png';

export default function Settings(){
  const userid = getFromStorage("user").userid;
  const name = getFromStorage("user").name;
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [pic, setPic] = useState("");
  const [img, setImg] = useState(null);
  const files = [pdf1, pdf2, pdf3, pdf4];

  useEffect(() => {
    if(getFromStorage("user").pic && getFromStorage("user").pic !== 'null'){
      setPic(url()+getFromStorage("user").pic);
    }
    axios.post(url()+"settings.php", { userid: userid })
    .then(res => {
      if(res.data.stat){
        setPhone(res.data.phone);
        setAddress(res.data.address);
        if(res.data.pic && res.data.pic !== 'null')
        setPic(url()+res.data.pic);
      }
    })
  }, []);

  const updateInfo = () => {
    const data = new FormData();
    data.append("userid", userid);
    data.append("phone", phone);
    data.append("address", address);
    if(img){
      data.append("pic", img);
    }else{
      data.append("pic", 1);
    }
    fetch(url()+"update_account.php", {
      method: 'POST',
      body: data,
      "Content-Type": 'multipart/form-data'
    })
    .then((response) => response.json())
    .then((res) => {
      if(res.stat){
        setPic(url()+res.pic);
        setInstorage("user", { userid: userid, name: name, pic: res.pic });
        Alert.success("Account Updated");
      }else{
        Alert.error("Unexpected Error");
      }
    })
  }

  const addImage = (file) => {
    if(file){
      setPic(URL.createObjectURL(file));
      setImg(file);
    }
  }

  return(
    <Styles>
      <Container>
        <Row style={{ marginTop: 20, width: '100%' }}>
          <Col sm="12" md="7">
            <Container>
              <input type="file" id="pic" accept="image/*" onChange={e => addImage(e.target.files[0])} style={{ display: 'none' }} />
              <center>
                {pic && 
                  <label for="pic">
                    <img src={pic} style={{ width: 120, height: 120, objectFit: 'cover', borderRadius: '50%' }} /><div/>
                    <label for="pic" className="form-label">Profile picture</label>
                  </label>
                }
                {!pic && 
                  <label for="pic">
                    <div style={{ width: 120, height: 120, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#d300ff', fontSize: 30 }}>
                      {name[0]}
                    </div>
                    <label for="pic" className="form-label">Profile picture</label>
                  </label>
                }
              </center>
              <div className="form-element">
                <label for="subject" className="form-label">Phone</label>
                <input type="text" onChange={(e) => setPhone(e.target.value)} value={phone} id="subject" placeholder="Phone" className="inputtext" />
              </div>
              <div className="form-element">
                <label for="address" className="form-label">Address</label>
                <input type="text" onChange={(e) => setAddress(e.target.value)} value={address} id="address" placeholder="Address" className="inputtext" />
              </div>
              <button onClick={() => updateInfo()} className="sendbtn">Update Account</button>
            </Container>
          </Col>

          <Col sm="12" md="5">
            <Container>
              <div className="pdffile">
                <img src={pdf} className="pdf" />
                <div className="pdfinfo oneline">
                  <h6 style={{ margin: 0, padding: 0 }} className="h6 oneline">Tutoring Contract</h6>
                  <p style={{ margin: 0, padding: 0, color: '#bbbbbb', fontSize: 13 }}>70 KB</p>
                </div>
                <a href={files[0]} download><img src={download} title="Download" className="downbtn" /></a>
              </div>
              <div className="pdffile">
                <img src={pdf} className="pdf" />
                <div className="pdfinfo oneline">
                  <h6 style={{ margin: 0, padding: 0 }} className="h6 oneline">Media Release Form</h6>
                  <p style={{ margin: 0, padding: 0, color: '#bbbbbb', fontSize: 13 }}>33 KB</p>
                </div>
                <a href={files[1]} download><img src={download} title="Download" className="downbtn" /></a>
              </div>
              <div className="pdffile">
                <img src={pdf} className="pdf" />
                <div className="pdfinfo oneline">
                  <h6 style={{ margin: 0, padding: 0 }} className="h6 oneline">COVID-19 LIABILITY WAIVER</h6>
                  <p style={{ margin: 0, padding: 0, color: '#bbbbbb', fontSize: 13 }}>71 KB</p>
                </div>
                <a href={files[2]} download><img src={download} title="Download" className="downbtn" /></a>
              </div>
              <div className="pdffile">
                <img src={pdf} className="pdf" />
                <div className="pdfinfo oneline">
                  <h6 style={{ margin: 0, padding: 0 }} className="h6 oneline">Terms and Conditions</h6>
                  <p style={{ margin: 0, padding: 0, color: '#bbbbbb', fontSize: 13 }}>43 KB</p>
                </div>
                <a href={files[3]} download><img src={download} title="Download" className="downbtn" /></a>
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </Styles>
  )
}

const Styles = styled.div`
  .form-element{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }

  .form-label{
    color: #1676dd;
    font-size: 13px;
    cursor: pointer;
  }

  .inputtext{
    width: 100%;
    padding: 4px;
    border: 1px solid #bbbbbb;
    outline: none;

    &: hover{
      border: 1px solid #1676dd;
    }

    &: focus{
      border: 1px solid #1676dd;
    }
  }

  .sendbtn{
    width: 100%;
    color: white;
    background-color: #1676dd;
    padding: 7px;
    outline: none;
    margin-bottom: 30px;

    &: hover{
      opacity: 0.8;
    }
  }

  .pdffile{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    border: 1px dashed #dbdbdb;
    margin-bottom: 15px;
  }

  .pdf{
    height: 100%;
  }

  .downbtn{
    height: 100%;
    border-left: 1px dashed #dbdbdb;

    &: hover{
      opacity: 0.7;
    }
  }

  .oneline{
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pdfinfo{
    flex: 1;
    border-left: 1px dashed #dbdbdb;
    padding-left: 7px;
  }

`;