import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from 'axios';

import { url } from "../../components/Url";
import TimeParser from "../../components/TimeParser";

import clock from "./../../assets/art/gclock.png";
import svg from "./../../assets/art/404.svg";
import { Select } from "antd";

export default function Overview(){
  const [trainings, setTrainings] = useState(null);
  const [users, setUsers] = useState(null);
  const [purchases, setPurchases] = useState(null);
  const [today, setToday] = useState([]);
  const history = useHistory();

  useEffect(() => {
    axios.post(url()+"admin/overview.php")
    .then(res => {
      if(res.data.stat == 1){
        setTrainings(res.data.trainings);
        setUsers(res.data.users);
        setPurchases(res.data.sales);
        setToday(res.data.res);
      }
    })
    .catch(err => alert(err));
  }, []);

  return(
    <Styles>
      <Container style={{ paddingTop: 25, backgroundColor: '#eeeeee', minHeight: '100vh' }}>
        <h5 className="h5" style={{ marginLeft: 30, marginBottom: 15 }}>Overview</h5>
        <Row className="summary">
          <Col sm="12" className="cola" md="3">
            <h5 className="h6" style={{ margin: 0, padding: 0 }}>Trainings</h5>
            <p style={{ color: '#05b1fb', margin: 0, padding: 0, marginTop: 3 }}>{trainings}</p>
          </Col>
          <Col sm="12" className="cola" md="3">
            <h5 className="h6" style={{ margin: 0, padding: 0 }}>Registered users</h5>
            <p style={{ color: '#05b1fb', margin: 0, padding: 0, marginTop: 3 }}>{users}</p>
          </Col>
          <Col sm="12" className="cola" md="3">
            <h5 className="h6" style={{ margin: 0, padding: 0 }}>Number of purchases</h5>
            <p style={{ color: '#05b1fb', margin: 0, padding: 0, marginTop: 3 }}>{purchases}</p>
          </Col>
        </Row>

        <h5 className="h5" style={{ marginLeft: 30, marginBottom: 15, marginTop: 20 }}>Today's Lessons</h5>
        <Row className="summary">
          {today.map(i => {
            const sessions = JSON.parse(i.sessions);
            return(
              <div className="training">
                <img loading="lazy" src={url()+i.pic} className="tpic" />
                <div className="tinfo">
                  <div style={{ borderBottom: '1px solid #dbdbdb', padding: 10 }}>
                    <h6 className="h6 oneline">{i.title}</h6>
                    <p className="oneline" style={{ fontSize: 13 }}>{i.desc}</p>
                  </div>
                  <div style={{ padding: 4, display: 'flex', flexDirection: 'row', borderBottom: '1px solid #dbdbdb', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <img loading="lazy" src={url()+i.photo} style={{ width: 25, height: 25, objectFit: 'cover', borderRadius: '50%' }} />
                    <p className="oneline">{i.trainer}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 14, color: '#bbbbbb' }}>
                      <img loading="lazy" src={clock} style={{ width: 25, height: 25, marginRight: 5 }} />
                      <p className="oneline">1 hour</p>
                    </div>
                  </div>
                  <div style={{ padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>Students: {i.students}</div>
                    <Select value="ses" style={{ flex: 1, marginLeft: 5 }}>
                      <option value="ses">Sessions</option>
                      {sessions.map((i, j) => {
                        return(
                          <option value={j} style={{ fontSize: 13, marginTop: 3, display: 'flex' }}>
                            <span style={{textTransform: 'uppercase', marginRight: 5}}>{i.day+" at "}</span>
                            <TimeParser time={i.time} />
                          </option>
                        )
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            )
          })}
          {today.lenght < 1 && <h4 style={{color: '#aaaaaa'}}>No trainings today</h4>}
        </Row>
      </Container>
    </Styles>
  )
}

const Styles = styled.div`
  .summary{
    width: 100%;
    padding-left: 25px;
  }

  .cola{
    height: 80px;
    background-color: white;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 15px;
    padding-left: 15px;
    justify-content: center;
  }

  .training{
    width: 250px;
    height: 300px;
    margin: 10px;
    box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.1);
    display: inline-block;
    background-color: white;
  }

  .tpic{
    width: 100%;
    height: 170px;
    object-fit: cover;
  }

  @media screen and (max-width: 770px){
    .training{
      width: 100%;
      height: 300px;
    }
  }

  .tinfo{
    width: 100%;
    height: 130px;
  }

  .oneline{
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

`;