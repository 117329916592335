import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Col, Row, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { Alert } from 'rsuite';
import { Select } from 'antd';

import MakePayment from './../components/StripeContainer';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { url } from '../components/Url';
import { getFromStorage } from '../components/Storage';

import TimeParser from '../components/TimeParser';
import RefreshContext from './../components/RefreshContext';

import close from './../assets/art/close.png';

export default function CourseDetails(){
  const userid = getFromStorage('user').userid;
  const params = useParams();
  const trainid = params.trainid;
  const [value, onChange] = useState(new Date());
  const [course, setCourse] = useState({});
  const [sessions, setSessions] = useState([]);
  const [values, setValues] = useState([]);
  const [mine, setMine] = useState(0);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [myses, setMyses] = useState({});
  const [sesin, setSesin] = useState(0);
  const [mysessions, setMysessions] = useState([]);
  const [minhases, setMinhases] = useState([]);
  const rest = { refresh, setRefresh };

  useEffect(() => {
    getDataFromApi();
  }, [trainid]);

  const dayparser = (i) => {
    if(i === "Mondays"){
      return 1
    }else if(i === "Tuesdays"){
      return 2
    }else if(i === "Wednesdays"){
      return 3
    }else if(i === "Thursdays"){
      return 4
    }else if(i === "Fridays"){
      return 5
    }else if(i === "Saturdays"){
      return 6
    }else if(i === "Sundays"){
      return 0
    }
  }

  useEffect(() => {
    const days = sessions.map(i => i.day);
    const dias = mysessions.map(i => i.day);
    setValues(days.map(i => {
      return dayparser(i)
    }));
    setMinhases(dias.map(i => dayparser(i)));
  }, [sessions]);

  useEffect(() => {
    setShow(false);
    getDataFromApi();
  }, [refresh]);

  useEffect(() => {
    if(refresh){
      const ses = sessions[sesin];
      axios.post(url()+"join_training.php", { trainid: parseInt(trainid), userid: parseInt(userid), session: JSON.stringify(ses) })
      .then(res => {
        if(res.data.stat){
          Alert.success("Successfully Joined Training");
          setMine(1);
          setRefresh(false);
        }
      })
    }
  }, [refresh]);

  const getDataFromApi = () => {
    axios.post(url()+"course.php", { userid: userid, trainid: trainid })
    .then(res => {
      if(res.data.stat == 1){
        setCourse(res.data.training);
        setSessions(JSON.parse(res.data.training.sessions));
        setMine(res.data.mine);
        setMysessions(Array(JSON.parse(res.data.myses)));
      }
    })
  }

  const rebookNow = () => {
    const ses = JSON.stringify(sessions[sesin]);
    const yam = mysessions.filter(i => JSON.stringify(i) === ses);
    if(yam.length > 0){
      return Alert.error("Choose a session");
    }else{
      setShow(true);
    }
  }

  const putContent = ({date}) => {
    const now = new Date(Date.now());
    if(minhases.includes(date.getDay()) && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()){
      return(<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} ><p style={{ padding: 0, margin: 0 }}>{date.getDay}</p><div style={{ width: 5, height: 5, borderRadius: '50%', backgroundColor: '#02f012' }}/></div>);
    }
    else if(values.includes(date.getDay()) && date.getMonth() == now.getMonth() && date.getFullYear() == now.getFullYear()){
      return(<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} ><p style={{ padding: 0, margin: 0 }}>{date.getDay}</p><div style={{ width: 5, height: 5, borderRadius: '50%', backgroundColor: 'purple' }}/></div>);
    }
  }

  const startDate = (d) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var date = new Date(d);
    const month = months[date.getMonth()];
    date = month+" "+date.getDate()+', '+date.getFullYear()
    return date;
  }

  const paymentModal = () => {
    return(
      <RefreshContext.Provider value={rest}>
      <Modal size="md" backdrop="static" style={{ color: '#424242', backgroundColor: 'rgba(0,0,0,0.7)' }} show={show} onHide={() => setShow(false)} >
        <img onClick={() => setShow(false)} src={close} style={{ alignSelf: 'flex-end', width: 15, height: 15, margin: 5, cursor: 'pointer' }} />
        <MakePayment trainid={trainid} />
      </Modal>
      </RefreshContext.Provider>
    )
  }

  return(
    <Styles>
      <div style={{ marginLeft: 10, width: '100%' }}>
        <Row style={{ width: '100%', marginTop: 20 }}>
          <Col sm="12" md="8">
            <div style={{ width: '100%' }}>
              <Calendar
                onChange={onChange}
                value={value}
                showNavigation={true}
                tileContent={putContent}
                style={{ width: '100%', border: 0 }}
                className="calendar"
                showNeighboringMonth={false}
              />
            </div>
            <div style={{ marginTop: 15, width: '100%', display: 'inline-block', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: 30 }}>
                  <div style={{ width: 5, height: 5, borderRadius: 3, backgroundColor: 'purple'  }} />
                </div>
                <div>
                  <h5 className="h6 oneline">{course.title}</h5>
                  {course.sdate && <p className="oneline" style={{ color: '#aaaaaa' }}>{startDate(course.sdate)}</p>}
                </div>
              </div>
              <div style={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', float: 'right' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', backgroundColor: 'purple', padding: 10, fontSize: '0.6em', height: 30, marginRight: 3 }}>1 hour</div>
                <Select defaultValue="ses" onChange={e => setSesin(e)} style={{ width: 200 }}>
                  <option disabled selected value="ses">Sessions</option>
                  {sessions.map((i, j) => {
                    const yam = mysessions.filter(m => JSON.stringify(m) === JSON.stringify(i));
                    if(yam.length > 0){
                      return(
                        <option disabled value={j} style={{ fontSize: 13, marginTop: 3, display: 'flex' }}>
                          <span style={{textTransform: 'uppercase', marginRight: 5}}>{i.day+" at "}</span>
                          <TimeParser time={i.time} />
                        </option>
                      )
                    }else{
                      return(
                        <option value={j} style={{ fontSize: 13, marginTop: 3, display: 'flex' }}>
                          <span style={{textTransform: 'uppercase', marginRight: 5}}>{i.day+" at "}</span>
                          <TimeParser time={i.time} />
                        </option>
                      )
                    }
                  })}
                </Select>
              </div>
            </div>
            {mine == 0 && <button onClick={() => setShow(true)} className="paybtn">Pay Now</button>}
            {mine == 1 && <button onClick={() => rebookNow()} className="paybtn">Rebook Training</button>}

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <div className="ftset"><div className="ftbtn" style={{ backgroundColor: 'purple' }} /> Available Sessions</div>
              <div className="ftset"><div className="ftbtn" style={{ backgroundColor: '#02f012' }} />Booked Sessions</div>
            </div>
          </Col>
          <Col sm="12" md="4">
            <div className="sidebox" style={{ padding: 10 }}>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <h6 className="h6">Course</h6>
                <div style={{ backgroundColor: '#fe0058', color: 'white', fontSize: 14, width: 35, height: 35, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><b>${course.price}</b></div>
              </div>
              <div style={{ marginTop: 10 }}>
                {course.desc}
              </div>
            </div>

            <div className="sidebox">
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, borderBottom: "1px solid #bbbbbb" }}>
                <img src={url()+course.photo} style={{ width: 35, height: 35, objectFit: 'cover', borderRadius: '50%', marginRight: 7 }} />
                <h6 className="h6" style={{ margin: 0, padding: 0 }}>{course.trainer}</h6>
              </div>
              <div style={{ marginTop: 10, padding: 10 }}>
                <h6 className="h6">Bio</h6>
                {course.bio}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {paymentModal()}
    </Styles>
  )
}

const Styles = styled.div`
  .sidebox{
    width: 100%;
    background-color: white;
    box-shadow: 1px 3px 5px 2px rgba(0,0,0,0.2);
    margin-bottom: 25px;
  }

  .calendar{
    width: 100%;
    border: 0px;
  }

  .oneline{
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .paybtn{
    padding: 4px 15px;
    background-color: orange;
    color: white;
    margin: 7px 0px;
    border-radius: 20px;
    font-size: 13px;
    outline: none;

    &:hover{
      opacity: 0.8;
    }
  }

  .ftset{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    margin-left: 15px;
  }

  .ftbtn{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 7px;
  }

`;