import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { CDBSidebar, CDBSidebarContent, CDBSidebarFooter, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem } from 'cdbreact';
import axios from 'axios';

import { deleteStorage } from './Storage';
import { url } from './Url';

import logo from './../assets/art/logo.png';

export default function AdminNavBar(){
  const [messages, setMessages] = useState(0);

  useEffect(() => {
    axios.post(url()+"admin/unread_messages.php")
    .then(res => {
      setMessages(res.data.count);
    })
  })

  const logout = () => {
    deleteStorage("admin");
    window.location.replace("/");
  }

  return(
    <Styles style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <div className="mainsidebar">
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <NavLink to="/" style={{ display: 'flex', flexDirection: 'column' }}>
            <img src={logo} style={{ width: '100%', height: 'auto' }} />
          </NavLink>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="desktop">Overview</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/addtraining" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="plus">Add Training</CDBSidebarMenuItem>
            </NavLink>
            <NavLink onClick={() => setMessages(0)} exact to="/messages" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="comments">Messages { messages > 0 && <span style={{ color: 'red' }}>{messages}</span> } </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/trainings" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="book">View Trainings</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/users" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="users">View Users</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center', borderTop: "1px solid #555" }}>
          <div onClick={() => logout()} style={{ padding: "20px 5px", cursor: 'pointer' }}>
            Log out
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .mainsidebar{
    position: -webkit-sticky;
    position: sticky;
    top: 0vh;
    height: 100vh;
  }

`;