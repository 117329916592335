import React, { useState, useEffect } from 'react';

import Guest from './Guest';
import Client from "./Client";
import Admin from "./Admin";

import LoginContext from "./LoginContext";
import { getFromStorage } from './components/Storage';

function App() {
  const [login, setLogin] = useState(getFromStorage("user"));
  const value = { login, setLogin };
  const admin = getFromStorage("admin");

  useEffect(() => {
    setLogin(getFromStorage("user"));
  }, []);

  if(admin){
    return <Admin />
  }

  return (
    <LoginContext.Provider value={value}>
    <div style={{ overflowX: 'hidden' }}>
      {!login && <Guest />}
      {login && <Client />}
    </div>
    </LoginContext.Provider>
  );
}

export default App;