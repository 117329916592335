import React from 'react';
import GoogleMapReact from 'google-map-react';

import './map.css';

const LocationPin = ({ text }) => (
  <div className="pin">
    <p className="pin-text">{text}</p>
  </div>
)

const Map = ({ zoomLevel }) => (
  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDVf31-1_5PC5lDz46FCUgqW3TqlrTeRYs' }}
        defaultCenter={{ lat: 30.332545145397464, lng: -81.66827474169686 }}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={30.332545145397464}
          lng={-81.66827474169686}
          text={'Aspiring Kids Learning Society, Jacksonville, Florida'}
        />
      </GoogleMapReact>
    </div>
  </div>
)

export default Map;