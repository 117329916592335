import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import AdminNavBar from './components/AdminNavBar';

import Overview from './screens/Admin/Overview';
import AddTraining from './screens/Admin/AddTraining';
import Users from './screens/Admin/Users';
import Trainings from './screens/Admin/Trainings';
import ViewTraining from './screens/Admin/ViewTraining';
import Messages from './screens/Admin/Messages';

export default function Admin(){
  return(
    <Router>
      <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height: '100vh' }}>
        <AdminNavBar />
        <Styles style={{ width: '100%', overflowY: 'auto' }}>
          <Switch>
            <Route path="/" component={Overview} exact />
            <Route path="/addtraining" component={AddTraining} />
            <Route path="/users" component={Users} />
            <Route path="/trainings" component={Trainings} exact />
            <Route path="/trainings/:trainid" component={ViewTraining} />
            <Route path="/messages" component={Messages} />
          </Switch>
        </Styles>
      </div>
    </Router>
  )
}

const Styles = styled.div`
  .onecourse{
    &: hover{
      box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.5);
    }
  }

`;