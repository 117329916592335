import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Modal, Container } from 'react-bootstrap';
import { Checkbox } from 'rsuite';

import pdf1 from './../docs/1.pdf';
import pdf2 from './../docs/2.pdf';
import pdf3 from './../docs/3.pdf';
import pdf4 from './../docs/4.pdf';

import { getFromStorage } from './Storage';
import { url } from './Url';
import search from "./../assets/art/search.png";

export default function ClientTopBar(){
  const user = getFromStorage("user");
  const [keyword, setKeyword] = useState("");
  const [active, setActive] = useState(null);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(1);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const files = [pdf1, pdf2, pdf3, pdf4];

  const dateParser = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const datestr = day+"/"+month+"/"+year;
    return datestr;
  }

  useEffect(() => {
    axios.post(url()+"check_active.php", { userid: user.userid })
    .then(res => {
      if(res.data.stat == 1){
        setActive(res.data.active);
        setName(res.data.name);
        setPhone(res.data.phone);
        setEmail(res.data.email);
        if(res.data.active == 0){
          setShow(true);
        }
      }
    })
  }, []);

  useEffect(() => {
    if(count > 4){
      axios.post(url()+"activate.php", { userid: user.userid })
      .then(res => {
        if(res.data.stat){
          setActive(true);
          setShow(false);
        }
      })
    }
  }, [count]);

  const putContract = () => {
    if(count == 1){
      return tutoringContract();
    }else if(count == 2){
      return mediaReleaseForm();
    }else if(count == 3){
      return covidLiabilityWaiver();
    }else if(count == 4){
      return termsAndConditions();
    }
  }

  const contractModal = () => {
    return(
      <Modal size="lg" show={show} onHide={() => setShow(false)}  style={{ color: '#424242', backgroundColor: 'rgba(0,0,0,0.6)' }} backdrop={"static"}>
        <div style={{ width: '100%', height: '85vh', display: 'flex', flexDirection: 'column' }}>
          {putContract()}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: "5px 15px", borderTop: '1px solid #bbbbbb' }}>
            <a href={files[count-1]} download className="btn btn-info" style={{ padding: "3px 10px" }}>Download Contract</a>
            <button onClick={() => setCount(count+1)} className="btn btn-success" style={{ padding: "3px 10px" }}>Agree and Continue</button>
          </div>
        </div>
      </Modal>
    )
  }

  const tutoringContract = () => {
    return(
      <Container style={{ overflowY: 'auto',  }}>
        <center><h4 className="h4">Tutoring Contract</h4></center>
        <p>This Tutoring Contract (the "Agreement") is made and entered on <b>{dateParser(Date.now())}</b> (by and between Grayer , SANTRICE 
          L (the "Tutor") from ASPIRING KIDS LEARNING SOCIETY, LLC of 6001 Argyle Forest Boulevard Suite 21, PMB 275, 
          Jacksonville, Florida 32244 and the following parent: 
        </p>
        <p style={{ margin: 15 }}>
          <table className="table table-striped">
            <tr>
              <td>Parent Name:</td>
              <td>{name}</td>
            </tr>
            <tr>
              <td>Parent Phone:</td>
              <td>{phone}</td>
            </tr>
            <tr>
              <td>Parent Email:</td>
              <td>{email}</td>
            </tr>
          </table>
        </p>
        <p>
          In this Agreement, the party who is contracting to arrange the tutoring services shall be referred to as "Parent", and the party 
          who will be providing the services shall be referred to as "Tutor." 
        </p>

        <p>
          <b>1. DESCRIPTION OF SERVICES.</b> Beginning on <b>{dateParser(Date.now())}</b>, the Tutor will provide educational instruction to:</p> 
 
          <p>The tutoring will take place online. The schedule is as follows:</p>
          
          <p><b>2. PAYMENT FOR SERVICES.</b> The Parent will compensate the Tutor for the services provided via our website at 
          www.aspiringkids.org. This compensation shall be payable in a lump sum prior to receiving services. </p>
          
          <p>No other fees and/or expenses will be paid to the Tutor, unless such fees and/or expenses have been approved in advance by 
          the appropriate executive on behalf of the Parent in writing. The Tutor shall be solely responsible for any and all taxes, Social 
          Security contributions or payments, disability insurance, unemployment taxes, and other payroll type taxes applicable to such 
          compensation. </p>
          
          <p><b>3. TERM/TERMINATION.</b> This Agreement shall terminate automatically on _________________. </p>
          
          <p>A regular, ongoing relationship of indefinite term is not contemplated. The Parent has no right to assign services to the Tutor 
          other than as specifically contemplated by this Agreement. However, the parties may mutually agree that the Tutor shall 
          perform other services for the Parent, pursuant to the terms of this Agreement. </p>
          
          <p><b>4. RELATIONSHIP OF PARTIES.</b> It is understood by the parties that the Tutor is an independent contractor with respect to 
          the Parents, and not an employee of the Parents. The Parentswill not provide fringe benefits, including health insurance 
          benefits, paid vacation, or any other employee benefit, for the benefit of the Tutor. </p>
          
          <p><b>5. ENTIRE AGREEMENT.</b> This Agreement constitutes the entire contract between the parties. All terms and conditions 
          contained in any other writings previously executed by the parties regarding the matters contemplated herein shall be deemed 
          to be merged herein and superseded hereby. No modification of this Agreement shall be deemed effective unless in writing and 
          signed by the parties hereto. </p>
          
          <p><b>6. WAIVER OF BREACH.</b> The waiver by the Parent of a breach of any provision of this Agreement by Tutor shall not 
          operate or be construed as a waiver of any subsequent breach by Tutor. </p>
          
          <p><b>7. SEVERABILITY.</b> If any provision of this Agreement shall be held to be invalid or unenforceable for any reason, the 
          remaining provisions shall continue to be valid and enforceable. If a court finds that any provision of this Agreement is invalid 
          or unenforceable, but that by limiting such provision it would become valid and enforceable, then such provision shall be 
          deemed to be written, construed, and enforced as so limited. </p>
          
          <p><b>8. APPLICABLE LAW.</b> This Agreement shall be governed by the laws of the State of Florida. </p>
          
          <p><b>9. SIGNATORIES.</b> This Agreement shall be signed by (Parent name) and by Grayer , SANTRICE L, on behalf of ASPIRING 
          KIDS LEARNING SOCIETY, LLC. This Agreement is effective as of the date first above written.
        </p>
      </Container>
    )
  }

  const mediaReleaseForm = () => {
    return(
      <Container style={{ overflowY: 'auto',  }}>
        <center><h4 className="h4">Media Release Form </h4></center>
        <p>This release concerns:
          <Checkbox checked={true}> photographs </Checkbox>
          <Checkbox style={{ marginTop: -10 }} checked={true}> videotape </Checkbox>
          <Checkbox style={{ marginTop: -10 }} checked={true}> film </Checkbox>
          <Checkbox style={{ marginTop: -10 }} checked={true}> audio </Checkbox>
          <Checkbox style={{ marginTop: -10 }} checked={true}> other media </Checkbox>
        </p>
        <p>These images or images and sounds are collectively referred to in this document as “works”</p>
        <p>I <b><u>{name}</u></b> grant permission to Aspiring Kids Learning 
          Society, LLC. and its employees and agents to use the attached works. These works 
          which contain my name, my image and/or voice may be used in educational and 
          documentary materials, such as public service announcements, grant applications, 
          video documentaries, printed newsletters, and on social media sites. Therefore, by 
          signing this release I give permission to Aspiring Kids Learning Society, LLC. to use the 
          works for program promotion, materials, and any other purposes Aspiring Kids 
          Learning Society, LLC. deems proper and necessary.
        </p>
        <p>
          I understand that my participation and my decision to provide AKLS with works are 
          strictly voluntary. I agree that my works may be used and disclosed by AKLS as 
          described in this form as long as AKLS uses the materials in the manner described. As 
          a result of signing this release form, I grant AKLS the following rights in the works:
        </p>
        <p>(a) irrevocable copyright in the name of AKLS </p>
        <p>(b) to use, re-use, publish and re-publish in whole or in part, individually or in conjunction with other photographs or images, in any medium, and </p>
        <p>(c) to place the works on the AKLS website and associated social media sites. </p>
        <p>
          I hereby release and discharge and hold harmless Aspiring Kids Learning Society, LLC., 
          its agents and employees, and any contractor or firm publishing and/or distributing 
          the works in any format from any and all claims and demands arising out of or in 
          connection with the use of the works, including but not limited to any claims for defamation, invasion of privacy, or any other claim. I acknowledge that I received no 
          financial compensation for the works taken of me. I am 18 years of age or older and 
          have read this release and fully understand the contents, meaning, and impact of this 
          release.
        </p>
      </Container>
    )
  }

  const covidLiabilityWaiver = () => {
    return(
      <Container style={{ overflowY: 'auto',  }}>
        <center>
          <h4 className="h4">COVID-19 LIABILITY WAIVER</h4>
          <h4 className="h4">READ CAREFULLY - THIS AFFECTS YOUR LEGAL RIGHTS</h4>
        </center>
        <p style={{ marginTop: 15 }}>In exchange for use of the property, facilities, and services of ASPIRING KIDS LEARNING SOCIETY, LLC, of 10298 
          Benson Lake Drive, Jacksonville, Florida, 32222, I, <b><u>{name}</u></b>, agree for myself and (if applicable) for the members of my family, to the 
          following:
        </p>
        <p><b>1. AGREEMENT TO FOLLOW DIRECTIONS.</b> I agree to observe and obey all posted rules and warnings regarding 
          COVID-19, and further agree to follow any oral instructions or directions given by ASPIRING KIDS LEARNING SOCIETY, 
          LLC, or the employees, representatives or agents of ASPIRING KIDS LEARNING SOCIETY, LLC. 
        </p>
        <p>
          <b>2. ASSUMPTION OF THE RISKS AND RELEASE.</b> I recognize that there are certain inherent risks associated with the 
          above described activity regarding COVID-19, acknowledge the contagious nature of COVID-19 and understand that CDC 
          and public health authorities recommend the practice of social distancing, and I assume full responsibility for personal injury 
          to myself and (if applicable) my family members, and further release and discharge ASPIRING KIDS LEARNING SOCIETY, 
          LLC for injury, loss or damage arising out of my or my family's use of or presence upon the facilities of ASPIRING KIDS 
          LEARNING SOCIETY, LLC, whether caused by the fault of myself, my family, ASPIRING KIDS LEARNING SOCIETY, 
          LLC or other third parties. 
        </p>
        <p>
          I acknowledge that ASPIRING KIDS LEARNING SOCIETY, LLC has followed all local and state requirements regarding the 
          coronavirus pandemic to reduce the spread of COVID-19. I acknowledge that ASPIRING KIDS LEARNING SOCIETY, LLC 
          cannot guarantee that I will not become infected with COVID-19. 
        </p>
        <p>
          <b>3. INDEMNIFICATION.</b> I agree to indemnify and defend ASPIRING KIDS LEARNING SOCIETY, LLC against all claims, 
          causes of action, damages, judgments, costs or expenses, including attorney fees and other litigation costs, which may in any 
          way arise from my or my family's use of or presence upon the facilities of ASPIRING KIDS LEARNING SOCIETY, LLC.
        </p>
        <p>
          <b>4. APPLICABLE LAW.</b> Any legal or equitable claim that may arise from participation in the above shall be resolved under Florida law. 
        </p>
        <p>
          <b>5. NO DURESS.</b> I agree and acknowledge that I am under no pressure or duress to sign this Agreement and that I have been 
          given a reasonable opportunity to review it before signing. I further agree and acknowledge that I am free to have my own 
          legal counsel review this Agreement if I so desire. I further agree and acknowledge that ASPIRING KIDS LEARNING 
          SOCIETY, LLC has offered to refund any fees I have paid to use its facilities if I choose not to sign this Agreement. 
        </p>
        <p>
          <b>6. ARM'S LENGTH AGREEMENT.</b> This Agreement and each of its terms are the product of an arm's length negotiation 
          between the Parties. In the event any ambiguity is found to exist in the interpretation of this Agreement, or any of its 
          provisions, the Parties, and each of them, explicitly reject the application of any legal or equitable rule of interpretation which 
          would lead to a construction either "for" or "against" a particular party based upon their status as the drafter of a specific term, 
          language, or provision giving rise to such ambiguity. 
        </p>
        <p>
          <b>7. ENFORCEABILITY.</b> The invalidity or unenforceability of any provision of this Agreement, whether standing alone or as 
          applied to a particular occurrence or circumstance, shall not affect the validity or enforceability of any other provision of this Agreement or of any other applications of such provision, as the case may be, and such invalid or unenforceable provision 
          shall be deemed not to be a part of this Agreement. 
        </p>
        <p><b>I HAVE READ THIS DOCUMENT AND UNDERSTAND IT. I FURTHER UNDERSTAND THAT BY SIGNING THIS RELEASE, I VOLUNTARILY SURRENDER CERTAIN LEGAL RIGHTS. </b></p>
      </Container>
    )
  }

  const termsAndConditions = () => {
    return(
      <Container style={{ overflowY: 'auto',  }}>
        <center>
          <h4 className="h4"><u>Terms and Conditions</u></h4>
        </center>
        <p style={{ marginTop: 15 }}>
          <b>Refund Policy</b>-Please be aware that we will issue a refund only if done so 24 hours before the start of a 
          scheduled tutoring session. All other refund requests, including, but not limited to, those made by 
          Students who cannot attend or use services, regardless of the student’s reason(s), will not be honored. 
          Exceptions may be made only in cases in which a Student cannot attend due to serious illness or family 
          emergency. In these cases, official documentation may be required. 
        </p>
        <p>
          <b>Cancellation Policy</b>-Parents/guardians are advised to cancel within 24 hours of their appointment’s 
          scheduled start time. However, Aspiring Kids LS recognizes that sometimes advanced notice is not 
          possible. Appointments canceled less than 6 hours of the appointment’s scheduled start time (or 15 
          minutes after the appointment has already started) are considered late cancellations, and will be charged 
          up to 50% of the total for the cancelled session. 
        </p>
        <p>
          <b>Late Arrival Policy</b>-Fees are calculated according to the times stipulated in the schedule and no 
          adjustment shall be made for time lost because of late arrival by the Student. Students who arrive late will 
          only be tutored for the remainder of the scheduled session. There will be no prolonged sessions. 
          Any lost time because of the late arrival of the Tutor shall be compensated for by extending a lesson by 
          mutual agreement and by such amount of time that was lost. 
        </p>
        <p>
          <b>No Show Policy</b>-If the Student is a no-show, he or she is still responsible for payment for the tutoring session. No discount or refunds shall be given in respect of lessons not attended by the Student. 
        </p>
        <p>
          <b>Agreement for Payment</b>-Payment shall be made before the start of a tutoring session OR Payment shall be made weekly/monthly in advance.
        </p>
        <p>
          *This agreement constitutes the entire understanding between the parties with regard to the subject 
          matter thereof and the parties waive the right to rely on any alleged expressed or implied provision not 
          contained herein. Any alteration to this agreement must be in writing and signed by both parties.* 
        </p>
      </Container>
    )
  }

  return(
    <Styles>
      <div className="topbar">
        <label className="searchbar" for="searchbar">
          <img src={search} style={{ width: 20, height: 20 }} />
          <input className="inputtxt" onChange={e => setKeyword(e.target.value)} id="searchbar" type="text" placeholder="Type in to search..." />
        </label>
        {(user.pic && user.pic !== 'null') && <img src={url()+user.pic} style={{ width: 40, height: 40, objectFit: 'cover', borderRadius: '50%' }} />}
        {(!user.pic || user.pic === 'null') && <div style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#d300ff' }}>
          {user && user.name[0]}
        </div>
        }
        <p className="d-none d-md-flex" style={{ margin: 0, padding: 0, marginLeft: 5 }}>{user && user.name}</p>
      </div>
      {contractModal()}
    </Styles>
  )
}

const Styles = styled.div`
  .topbar{
    width: 100%;
    flex: 1;
    background-color: white;
    box-shadow: 1px 3px 5px 2px rgba(0,0,0,0.2);
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px;
  }

  .searchbar{
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    padding: 0px;
  }

  .inputtxt{
    flex: 1;
    padding-left: 10px;
    border: 0px;
    outline: none;
  }

`;