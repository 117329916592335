import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Alert } from 'rsuite';
import styled from 'styled-components';
import axios from 'axios';

import { getFromStorage, setInstorage } from '../components/Storage';
import { url } from '../components/Url';

export default function AdminLogin(){
  const history = useHistory();
  const [mail, setMail] = useState('');
  const [pass, setPass] = useState('');

  useEffect(() => {
    if(getFromStorage("admin")){
      history.push("/");
    }
  })

  const handleLogin = (e) => {
    e.preventDefault();
    if(!mail || !pass){
      return Alert.error("Incomplete Request");
    }
    const data = { mail, pass }
    axios.post(url()+"admin_login.php", data)
    .then(res => {
      if(res.data.stat == 1){
        const admin = { id: res.data.id, name: res.data.name };
        setInstorage("admin", admin);
        window.location.replace("/");
      }
    })
  }

  return(
    <Styles>
      <div className="main">
        <Container>
          <Row style={{ height: '45vh' }}>
            <Col lg="5">
              <div className="form-control form">
                <h3>Welcome back</h3>
                <center>
                  <div>
                    <input value={mail} onChange={e => setMail(e.target.value)} type="text" autoComplete="off" name="mail" placeholder="Email Address" className="input" /><br />
                    <input value={pass} onChange={e => setPass(e.target.value)} type="password" name="password" placeholder="Password" className="input" /><br />
                    <input onClick={e => handleLogin(e)} type="submit" className="btn-primary submit" value="Sign In" />
                  </div>
                </center>
              </div>
            </Col>

            <Col className="d-none d-md-flex" style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 10, backgroundColor: '#007aff', color: 'white' }}>
              <div>
                <big>This section of the app is reserved only for site administrators, please provide your login credentials to authenticate yourself.</big>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .main{
    height: 80vh;
    padding-top: 15vh;
    background-color: #fbfbfb;
    width: 100%;
    padding-bottom: 30px;
  }

  .form{
    width: 100%;
    height: 100%;
  }

  .input{
    width: 90%;
    margin-top: 10px;
    border: 1px solid gray;
    border-radius: 3px;
    height: 35px;
    padding-left: 10px;

    &:hover{
      border: 1px solid #007aff;
    }
  }

  .submit{
    width: 90%;
    margin-top: 4px;
    border-radius: 3px;
    padding: 5px;
    cursor: pointer;
  }

  .aside{
    font-size: 14px;
    margin-top: 10px;
  }

`;