import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Notification, Alert } from 'rsuite';
import styled from 'styled-components';
import axios from 'axios';

import { url } from "./../components/Url";
import { getFromStorage } from '../components/Storage';

export default function MessageSupport(){
  const userid = getFromStorage("user").userid;
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendMessage = () => {
    if(!title || !message){
      return Alert.error("Please fill all fields");
    }
    if(!loading){
      setLoading(true);
      const data = { userid: userid, title: title, message: message };
      axios.post(url()+"addquery.php", data)
      .then(res => {
        if(res.data.stat == 1){
          Notification.open({
            title: <b>Message Sent</b>,
            duration: 20000,
            description: "Thank you for contacting us, a member of our team will review your request and get back to you shortly."
          });
          setMessage("");
          setTitle("");
        }else{
          Notification.open({
            title: <b>Message Not Sent</b>,
            duration: 20000,
            description: "Your message could not be sent at this time, please try again later"
          });
        }
      })
      setLoading(false);
    }else{
      Alert.info("Sending Message");
    }
  }

  return(
    <Styles>
      <Row style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', marginTop: 15 }}>
        <Col sm="12" md="8">
          <Container>
            <div className="form-element">
              <label for="subject" className="form-label">Subject</label>
              <input type="text" onChange={(e) => setTitle(e.target.value)} value={title} id="subject" placeholder="Subject" className="inputtext" />
            </div>
            <div className="form-element">
              <label for="mail" className="form-label">Message</label>
              <textarea value={message} onChange={e => setMessage(e.target.value)} type="text" id="mail" placeholder="Write your message..." className="inputtext" style={{ resize: 'none', height: 130 }}></textarea>
            </div>
            <button onClick={() => sendMessage()} className="sendbtn">Submit</button>
          </Container>
        </Col>
        <Col sm="12" md="3">
          <Container>
            Please explain your query in as much detail as possible. This will help us in quickly identifying and resolving your issues.
          </Container>
        </Col>
      </Row>
    </Styles>
  )
}

const Styles = styled.div`
  .form-element{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }

  .form-label{
    color: #1676dd;
    font-size: 13px;
  }

  .inputtext{
    width: 100%;
    padding: 4px;
    border: 1px solid #bbbbbb;
    outline: none;

    &: hover{
      border: 1px solid #1676dd;
    }

    &: focus{
      border: 1px solid #1676dd;
    }
  }

  .sendbtn{
    width: 100%;
    color: white;
    background-color: #1676dd;
    padding: 7px;
    outline: none;
    margin-bottom: 30px;

    &: hover{
      opacity: 0.8;
    }
  }

`;