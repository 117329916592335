import React, { useState, useEffect } from "react";
import { Container, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from 'axios';

import { url } from "../../components/Url";

import trash from "./../../assets/art/trash.png";
import close from "./../../assets/art/close.png";

export default function Trainings(){
  const [today, setToday] = useState([]);
  const [selected, setSelected] = useState({});
  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axios.post(url()+"admin/trainings.php")
    .then(res => {
      if(res.data.stat == 1){
        setToday(res.data.res);
      }
    })
  }, []);

  const deleteTraining = () => {
    const data = { trainid: selected.id }
    axios.post(url()+"admin/delete_training.php", data)
    .then(res => {
      if(res.data.stat == 1){
        setToday(
          today.filter(i => i !== selected)
        )
        setShow(false);
      }
    })
  }

  const selectTraining = (t) => {
    setSelected(t);
    setShow(true);
  }

  const GroupModal = () => {
    return(
      <Modal size="md" show={show} onHide={() => setShow(false)}>
        <div style={{ width: '100%', height: '100%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', height: '200px', color: '#424242', borderRadius: 5 }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: 5 }}>  
            <img src={close} onClick={() => setShow(false)} style={{ width: 15, height: 15, cursor: 'pointer' }} />
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h3>Delete Training</h3>
            <p>Are you sure you want to delete the training <b>{selected.title}</b>?</p>
            <button onClick={() => setShow(false)} style={{ padding: "4px 10px", color: 'black', backgroundColor: 'white', border: '1px solid black', borderRadius: 2, marginRight: 10 }}>Cancel</button>
            <button onClick={() => deleteTraining()} style={{ padding: "4px 10px", color: 'red', backgroundColor: 'white', border: '1px solid red', borderRadius: 2 }}>Delete</button>
          </div>
        </div>
      </Modal>
    )
  }

  return(
    <Styles>
      <Container style={{ paddingTop: 25, backgroundColor: '#eeeeee', minHeight: '100vh' }}>
        <h5 className="h5" style={{ marginLeft: 30, marginBottom: 15, marginTop: 20 }}>Trainings</h5>
        <Row className="summary">
          {today.map(i => {
            const sessions = JSON.parse(i.sessions);
            return(
              <div className="training">
                <img loading="lazy" src={url()+i.pic} className="tpic" />
                <div className="tinfo">
                  <div style={{ borderBottom: '1px solid #dbdbdb', padding: 10 }}>
                    <h6 className="h6 oneline">{i.title}</h6>
                    <p className="oneline" style={{ fontSize: 13 }}>{i.desc}</p>
                  </div>
                  <div style={{ padding: 4, display: 'flex', flexDirection: 'row', borderBottom: '1px solid #dbdbdb', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <img loading="lazy" src={url()+i.photo} style={{ width: 25, height: 25, marginRight: 5, objectFit: 'cover', borderRadius: '50%' }} />
                      <p className="oneline">{i.trainer}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 14, color: '#bbbbbb' }}>
                      <img loading="lazy" onClick={() => selectTraining(i)} src={trash} style={{ width: 20, height: 20, marginRight: 2, cursor: 'pointer' }} />
                    </div>
                  </div>
                  <div style={{ padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>Students: {i.students}</div>
                    <button onClick={() => history.push('/trainings/'+i.id)} className="viewbtn">View Students</button>
                  </div>
                </div>
              </div>
            )
          })}
          {today.lenght < 1 && <h4 style={{color: '#aaaaaa'}}>No trainings</h4>}
        </Row>
      </Container>
      {GroupModal()}
    </Styles>
  )
}

const Styles = styled.div`
  .summary{
    width: 100%;
    padding-left: 25px;
  }

  .cola{
    height: 80px;
    background-color: white;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 15px;
    padding-left: 15px;
    justify-content: center;
  }

  .training{
    width: 250px;
    height: 300px;
    margin: 10px;
    box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.1);
    display: inline-block;
    background-color: white;
  }

  .tpic{
    width: 100%;
    height: 170px;
    object-fit: cover;
  }

  .viewbtn{
    padding: 4px 15px;
    background-color: orange;
    color: white;
    margin: 7px 0px;
    border-radius: 20px;
    font-size: 13px;
    outline: none;

    &:hover{
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 770px){
    .training{
      width: 100%;
      height: 300px;
    }
  }

  .tinfo{
    width: 100%;
    height: 130px;
  }

  .oneline{
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

`;