import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Alert } from 'rsuite';
import styled from 'styled-components';
import axios from 'axios';

import { url } from './../components/Url';

import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/en';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(frenchStrings);

export default function GetAllReviews(){
  const [reviews, setReviews] = useState([]);
  const [last, setLast] = useState(20000);
  const [more, setMore] = useState(true);
  const colors = ['#42ace1', '#d300ff', '#ff0059', 'orange', '#00ff2d', '#5aff00', '#b1b0b1', '#bb023b'];

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = () => {
    axios.post(url()+"allreviews.php", { last: last })
    .then(res => {
      if(res.data.length > 0){
        const fin = res.data.length - 1;
        setReviews([...reviews, ...res.data]);
        setLast(res.data[fin].id);
      }
      if(res.data.length < 2){
        setMore(false);
      }
    })
  }


  return(
    <Styles style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="revcontainer">
        {reviews.map(i => {
          return(
            <div className="revbox">
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: 50, height: 50, backgroundColor: colors[Math.floor(Math.random() * 8)], borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>{i.name[0]}</div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                  <h6 className="h6" style={{ marginBottom: -7 }}>{i.name}</h6>
                  <TimeAgo 
                    formatter={formatter}
                    date={JSON.stringify(i.timestamp)}
                    style={{ fontSize: 13 }}
                  />
                  <p style={{ marginTop: 7, fontSize: 15 }}>{i.review}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <center style={{ marginBottom: 40 }}>
        <button className="morebtn" disabled={!more} onClick={() => fetchReviews()}>See More</button>
      </center>
    </Styles>
  )

}

const Styles = styled.div`
  .revcontainer{
    width: 100%;
    display: inline-block;
  }

  .revbox{
    width: 450px;
    max-width: 80%;
    margin: 10px;
    margin-right: 9%;
    display: inline-block;
    background-color: white;
    padding: 10px;
    box-shadow: 1px 2px 5px 1px rgba(1,117,216,0.3);
    border-radius: 5px;
  }

  .morebtn{
    background-color: transparent;
    color: #0175d8;
  }

  @media screen and (max-width: 770px){
    .revbox{
      width: 90%;
      max-width: 90%;
    }
  }

`;