import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Rate } from 'antd';

import { url } from './../components/Url';

import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/en';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(frenchStrings);

export default function GetReviews(){
  const [reviews, setReviews] = useState([]);
  const colors = ['#42ace1', '#d300ff', '#ff0059', 'orange', '#00ff2d', '#5aff00', '#b1b0b1', '#bb023b'];

  useEffect(() => {
    axios.post(url()+"getreviews.php")
    .then(res => {
      setReviews(res.data);
    })
    
  }, []);

  return(
    <Styles>
      <h2 className="h2" style={{ fontFamily: 'sans-serif' }}>Aspiring Kids</h2>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -10, marginBottom: 10 }}>
        <span style={{ fontSize: 20, margin: 0, padding: 0, marginRight: 5 }}>4.8</span>
        <Rate allowHalf value={4.5} disabled style={{ margin: 0, padding: 0 }} />
      </div>
      <div className="revcontainer">
        {reviews.map(i => {
          return(
            <div className="revbox">
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: 50, height: 50, backgroundColor: colors[Math.floor(Math.random() * 8)], borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>{i.name[0]}</div>
                <div style={{ flex: 1, marginLeft: 10 }}>
                  <h6 className="h6" style={{ marginBottom: -7 }}>{i.name}</h6>
                  <TimeAgo 
                    formatter={formatter}
                    date={JSON.stringify(i.timestamp)}
                    style={{ fontSize: 13 }}
                  />
                  <p style={{ marginTop: 7, fontSize: 15 }}>{i.review}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .revcontainer{
    width: 100%;
    display: inline-block;
  }

  .revbox{
    width: 40%;
    margin: 1%;
    margin-right: 9%;
    display: inline-block;
    background-color: white;
  }

  @media screen and (max-width: 700px){
    .revbox{
      width: 90%;
    }
  }

`;