import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import TimeParser from '../../components/TimeParser';

import { url } from "../../components/Url";

export default function ViewTraining(){
  const history = useHistory();
  const params = useParams();
  const trainid = params.trainid;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.post(url()+"admin/view_training.php", { trainid: trainid })
    .then(res => {
      if(res.data.stat == 1){
        setUsers(res.data.users);
      }
    })
  }, [trainid]);

  return(
    <Styles>
      <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Session</th>
              </tr>
            </thead>
            <tbody>
              {users.map(i => {
                return(
                  <tr>
                    <td>{i.name}</td>
                    <td>{i.phone}</td>
                    <td>{i.email}</td>
                    <td>
                      <div style={{ fontSize: 13, marginTop: 3, display: 'flex' }}>
                        <span style={{textTransform: 'uppercase', marginRight: 5}}>{JSON.parse(i.session).day+" at "}
                        <TimeParser time={JSON.parse(i.session).time} /></span>
                      </div>  
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
    </Styles>
  );
}

const Styles = styled.div``;