import React, { useState, useEffect } from "react";
import { Container, Col, Modal } from "react-bootstrap";
import styled from "styled-components";
import axios from 'axios';
import LinesEllipsis from 'react-lines-ellipsis';

import { url } from "../../components/Url";
import close from './../../assets/art/close.png';

import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/en-short';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(frenchStrings);

export default function Messages(){
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState({});
  const [show, setShow] = useState(false);
  const colors = ['#42ace1', '#d300ff', '#ff0059', 'orange', '#00ff2d', '#5aff00', '#b1b0b1', '#bb023b'];

  useEffect(() => {
    axios.post(url()+"admin/messages.php")
    .then(res => {
      setMessages(res.data.messages);
    })
  }, []);

  const selectMessage = (e) => {
    setMessage(e);
    setShow(true);
    setMessages(
      messages.map(i => {
        if(i.id == e.id){
          i.seen = 1;
          return i;
        }else{
          return i;
        }
      })
    )
  }

  const messageModal = () => {
    const styles = {
      onemessage: {
        backgroundColor: 'white',
        margin: '10px',
        marginTop: 0,
        borderRadius: '10px',
      },
    
      headband: {
        width: '100%',
        height: 35,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #dbdbdb',
        paddingRight: 10,
      },
    
      pic: {
        width: 30,
        height: 30,
        borderRadius: 5,
        objectFit: 'cover'
      },
    
      msgbody: {
        padding: 10,
        paddingBottom: 0,
        maxHeight: '60vh',
        minHeight: '35vh',
        overflowY: 'auto'
      },
    
      msgbodyp: {
        fontSize: 14
      }
    }
    if(message.title){
      return(
        <Modal aria-labelledby="contained-modal-title-vcenter" centered size="md"  style={{ color: '#424242', backgroundColor: 'rgba(0,0,0,0.7)', paddingTop: -400 }} show={show} onHide={() => setShow(false)} >
          <img onClick={() => setShow(false)} src={close} style={{ alignSelf: 'flex-end', width: 15, height: 15, margin: 5, marginBottom: 0, cursor: 'pointer' }} />
          <div style={styles.onemessage}>
            <div style={styles.headband}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                {(message.pic && message.pic !== 'null') && <img src={url()+message.pic} style={styles.pic} />}
                {(!message.pic || message.pic === 'null') && <div style={{ width: 30, height: 30, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: colors[Math.floor(Math.random() * 8)] }} >{message.name[0]}</div>}
                <b style={{ marginLeft: 5 }}>{message.name}</b>
              </div>
              <TimeAgo 
                formatter={formatter}
                date={JSON.stringify(message.timestamp)}
              />
            </div>
            <div style={styles.msgbody}>
              <h5 className="h5">{message.title}</h5>
              <p style={styles.msgbodyp}>{message.message}</p>
            </div>
          </div>
          <div style={{ margin: 15, marginTop: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <a href={`mailto:${message.email}`} style={{ margin: 0, padding: 0 }}>{message.email}</a>
            <a href={`tel:${message.phone}`} style={{ margin: 0, padding: 0 }}>{message.phone}</a>
          </div>
        </Modal>
      )
    }
  }

  return(
    <Styles>
      <Container style={{ paddingTop: 25, backgroundColor: '#eeeeee', minHeight: '100vh' }}>
        <h5 className="h5" style={{ marginLeft: 30, marginBottom: 15 }}>Messages</h5>
        <Col sm="12" md="9" style={{ padding: 0, margin: 0 }}>
        {messages.map(i => {
          return(
            <div onClick={() => selectMessage(i)} className="onemessage">
              <div className="headband">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
                  {(i.pic && i.pic !== 'null') && <img src={url()+i.pic} className="pic" />}
                  {(!i.pic || i.pic === 'null') && <div className="pic" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: colors[Math.floor(Math.random() * 8)] }} >{i.name[0]}</div>}
                  <b style={{ marginLeft: 5 }}>{i.name}</b>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <TimeAgo 
                    formatter={formatter}
                    date={JSON.stringify(i.timestamp)}
                  />
                  { i.seen == 0 && <div style={{ marginLeft: 10, width: 10, height: 10, borderRadius: '50%', background: 'red' }} />}
                </div>
              </div>
              <div className="msgbody">
                <h5 className="h5">{i.title}</h5>
                <p>
                  <LinesEllipsis
                    text={i.message}
                    maxLine='3'
                    ellipsis={<>...<span style={{ color: '#42ace1' }}>keep reading</span></>}
                    trimRight
                    basedOn='letters'
                  />
                </p>
              </div>
            </div>
          )
        })}
        </Col>
      </Container>
      {messageModal()}
    </Styles>
  )
}

const Styles = styled.div`
  .onemessage{
    width: 100%;
    background-color: white;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1);
    cursor: pointer;

    &: hover{
      box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.4);
    }
  }

  .headband{
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    padding-right: 10px;
  }

  .pic{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    object-fit: cover;
  }

  .msgbody{
    padding: 10px;
  }

  .msgbody p{
    font-size: 14px;
  }

`;