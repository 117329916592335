import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar, Nav, Modal, Row, Col, Container } from 'react-bootstrap';
import { Alert } from 'rsuite';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

import { url } from './Url';
import { setInstorage } from './Storage';

import logo from './../assets/art/logo.png';
import modimg from './../assets/img/3.jpg';
import close from './../assets/art/close.png';
import LoginContext from '../LoginContext';

export default function NavBar(){
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [mail, setMail] = useState('');
  const [pass, setPass] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [enrol, setEnrol] = useState(false);
  const {login, setLogin} = useContext(LoginContext);

  const [recoverEmail, setRecoverEmail] = useState('');
  const [recov, setRecov] = useState(false);


  function enrolUser(){
    if(!name || !phone || !email || !password){
      return Alert.error("Please fill all fields");
    }
    const data = { name: name, phone: phone, email: email, password: password }
    axios.post(url()+"signup.php", data)
    .then(res => {
      if(res.data.stat == 1){
        const data = { name: name, userid: res.data.userid };
        setInstorage("user", data);
        setLogin(data);
        history.push("/");
      }
    })
  }

  function loginUser(){
    if(!mail || !pass){
      return Alert.error("Please fill all fields");
    }
    const data = { mail: mail, pass: pass }
    console.log('submittt')
    axios.post(url()+"login.php", data, {withCredentials: false})
    .then(res => {
      if(res.data.stat == 1){
        console.log('logged in')
        const data = { name: res.data.name, userid: res.data.userid, pic: res.data.pic };
        setInstorage("user", data);
        setLogin(data);
        history.push("/");
      }else{
        console.log('wrong')
        return Alert.error("Invalid Login");
      }
    })
  }


  const passwordForgot= () =>{
    if(!recoverEmail){
      return Alert.error("Your email address is required");
    }
    const data = { mail: recoverEmail }
    console.log('submittt');
    axios.post(url()+"recoverpass.php", data, {withCredentials: false, headers: {
      'Content-Type': 'application/json'
  }})
    .then(res => {
      console.log(res.data);
      if(res.data.stat === 1){
        return Alert.success("Recover link has been sent to your email");
      }else{
        return Alert.error("User not found");
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const enrolModal = () => {
    const style = {
      inputtxt: {
        width: '100%',
        height: '40px',
        padding: "5px 15px",
        borderRadius: "20px",
        border: "1px solid #ababab",
        marginBottom: 10,
        outline: 'none'
      },
      btn: {
        width: '100%',
        height: '35px',
        borderRadius: "20px",
        border: "1px solid #ababab",
        outline: 'none'
      }
    }
    return(
      <Modal size="lg" style={{ color: '#424242', }} backdrop={"static"} show={enrol} onClose={() => setShow(true)}>
        <Row>
          <Col md="5" className="d-none d-md-block" style={{ height: 400, backgroundImage: `url(${modimg})`, padding: 0, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }}>
            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(66, 172, 224,0.7)', borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }}></div>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <img onClick={() => setEnrol(false)} src={close} style={{ alignSelf: 'flex-end', width: 15, height: 15, margin: 5, cursor: 'pointer' }} />
            <div style={{ width: '100%', padding: 15 }}>
              <h4 className="h4">Welcome</h4>
              <p>Please fill out this form to help us setup your profile.</p>
              <Container>
                <input style={style.inputtxt} type="text" placeholder="Full Name" onChange={e => setName(e.target.value)} />
                <input style={style.inputtxt} type="text" placeholder="Phone" onChange={e => setPhone(e.target.value)} />
                <input style={style.inputtxt} type="text" placeholder="Email Address" onChange={e => setEmail(e.target.value)} />
                <input style={style.inputtxt} type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                <button onClick={() => enrolUser()} style={style.btn} className="btn-primary" >Enroll Now</button>
              </Container>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  const loginModal = () => {
    const style = {
      inputtxt: {
        width: '100%',
        height: '40px',
        padding: "5px 15px",
        borderRadius: "20px",
        border: "1px solid #ababab",
        marginBottom: 10,
        outline: 'none'
      },
      btn: {
        width: '100%',
        height: '35px',
        borderRadius: "20px",
        border: "1px solid #ababab",
        outline: 'none'
      }
    }
    return(
      <Modal size="lg" style={{ color: '#424242', }} backdrop={"static"} show={show} onClose={() => setShow(true)}>
        <Row>
          <Col md="5" className="d-none d-md-block" style={{ height: 400, backgroundImage: `url(${modimg})`, padding: 0, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }}>
            <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(66, 172, 224,0.7)', borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }}></div>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <img onClick={() => setShow(false)} src={close} style={{ alignSelf: 'flex-end', width: 15, height: 15, margin: 5, cursor: 'pointer' }} />
            <div style={{ width: '100%', padding: 15 }}>
              <h4 className="h4">Sign In</h4>
              <p>Welcome Back! Please sign in to your account to continue.</p>
              <Container>
                <input style={style.inputtxt} type="text" placeholder="Email Address" onChange={e => setMail(e.target.value)} />
                <input style={style.inputtxt} type="password" placeholder="Password" onChange={e => setPass(e.target.value)} />
                <button onClick={() => loginUser()} style={style.btn} className="btn-primary" >Sign In</button>
              </Container>
              <div style={{width: '100%', marginTop: 30}}>
                <span onClick={()=> [setShow(false), setRecov(true)]}
                style={{color: 'blue', textDecoration: 'underline', textAlign: 'center', width: '100%'}}>
                  Forgot password ?
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  const forgotpasswordModal = () => {
    const style = {
      inputtxt: {
        width: '100%',
        height: '40px',
        padding: "5px 15px",
        borderRadius: "20px",
        border: "1px solid #ababab",
        marginBottom: 10,
        outline: 'none'
      },
      btn: {
        width: '100%',
        height: '35px',
        borderRadius: "20px",
        border: "1px solid #ababab",
        outline: 'none'
      }
    }
    return(
      <Modal size="lg" style={{ color: '#424242', }} backdrop={"static"} show={recov} onClose={() => setRecov(true)}>
        <Row>
          <Col md="5" className="d-none d-md-block" style={{ height: 400, backgroundImage: `url(${modimg})`, padding: 0, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }}>
            <div 
            style={{ width: '100%', height: '100%', backgroundColor: 'rgba(66, 172, 224,0.7)', borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }}></div>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <img onClick={() => setRecov(false)} src={close} style={{ alignSelf: 'flex-end', width: 15, height: 15, margin: 5, cursor: 'pointer' }} />
            <div style={{ width: '100%', padding: 15 }}>
              <h4 className="h4">Recover password</h4>
              <p>Please enter your email address for password recovery.</p>
              <Container>
                <input style={style.inputtxt} type="text" placeholder="Email Address" onChange={e => setRecoverEmail(e.target.value)} />
                <button onClick={() => passwordForgot()} style={style.btn} className="btn-primary" >Recover</button>
              </Container>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }


  return(
    <Styles>
      <Navbar style={{ borderBottom: '1px solid #dbdbdb' }} bg="white" variant="light" className="navbar-toggle" expand="lg" fixed="top">
        <Navbar.Brand href="/"><img src={logo} style={{ height: '7vh' }} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto ml-auto">
            <NavLink exact to="/" activeClassName="anavlink" className="navlink">HOME<div/></NavLink>
            <NavLink to="/team" activeClassName="anavlink" className="navlink">MEET THE TEAM<div/></NavLink>
            <NavLink to="/services" activeClassName="anavlink" className="navlink">SERVICES<div/></NavLink>
            <NavLink to="/aboutus" activeClassName="anavlink" className="navlink">ABOUT US<div/></NavLink>
            <NavLink to="/reviews" activeClassName="anavlink" className="navlink">REVIEWS<div/></NavLink>
            <NavLink to="/contact" activeClassName="anavlink" className="navlink">CONTACT<div/></NavLink>
          </Nav>

          <Nav>
            <button onClick={() => setShow(true)} className="actbtn">Sign in</button>
            <button onClick={() => setEnrol(true)} className="actbtn">Enroll now</button>
          </Nav>

        </Navbar.Collapse>
      </Navbar>
      {loginModal()}
      {enrolModal()}
      {forgotpasswordModal()}
    </Styles>
  )
}

const Styles = styled.div`
  .navlink{
    padding: 4px 10px 4px 10px;
    text-decoration: none;
    font-size: 14px;
    color: #424242;
    align-items: center;
    display: flex;
    flex-direction: column;

    &: hover{
      color: #42ace1;
    }
  }

  .anavlink{
    font-weight: bold;
    color: #42ace1;
  }

  .anavlink div{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #42ace1;
  }

  #dropdown-basic {
    background-color: #F8F8FF;
    border: 0px;
    color: slategray;
  }

  .actbtn{
    border-radius: 20px;
    color: #42ace1;
    border: 1px solid #42ace1;
    background-color: white;
    margin-right: 15px;
    font-size: 13px;
    padding: 4px 10px;
    outline: none;

    &: hover{
      color: white;
      background-color: #42ace1;
    }
  }
    
`;