import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Alert } from 'rsuite';

import Map from '../components/Map';
import { url } from '../components/Url';

import tron from './../assets/img/9.jpg';
import axios from 'axios';

export default function Contact(){
  const history = useHistory();
  const [subject, setSubject] = useState('');
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    setTimeout(() => scrollToTop(), 0.1*1000)
  }, []);

  const sendMessage = () => {
    if(!subject || !fullname || !email || !message){
      return Alert.error("Please fill all fields");
    }
    axios.post(url()+"contact.php", { subject, fullname, email, message })
    .then(res => {
      if(res.data.stat){
        Alert.success("Message Sent");
        setSubject("");
        setFullname("");
        setEmail("");
        setMessage("");
      }else{
        Alert.error("Error Sending Message");
      }
    })
  }

  const scrollToTop = () => {
    const elmnt = document.getElementById("top");
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  return(
    <Styles>
      <div id="top" style={{ position: 'absolute', top: -50, width: 1, height: 1 }} />
      <div className="headimg">
        <div className="overlay">
          <Container style={{ padding: '4%' }}>
            <h4 className="h2" style={{ margin: 0 }}>Contact</h4>
            <nav style={{ background: 'transparent', marginLeft: 0, paddingLeft: 0 }} aria-label="breadcrumb">
              <ol style={{ background: 'transparent' }} class="breadcrumb">
                <li class="breadcrumb-item"><Link style={{ color: 'white' }} to="/">Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">contact</li>
              </ol>
            </nav>
          </Container>
        </div>
      </div>
      <Container style={{ padding: '2% 4%' }}>
        <h4 className="h2" style={{ margin: 0, marginTop: 20, marginBottom: 20, color: '#42ace1' }}>Leave us a message</h4>
        <Row style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
          <Col sm="10" md="6">
            <input type="text" placeholder="Full name" value={fullname} className="inputtxt" onChange={e => setFullname(e.target.value)} />
            <input type="email" placeholder="Email Address" value={email} className="inputtxt" onChange={e => setEmail(e.target.value)} />
            <input type="text" placeholder="Subject" value={subject} className="inputtxt" onChange={e => setSubject(e.target.value)} />
            <textarea placeholder="Write your message" value={message} className="textarea" onChange={e => setMessage(e.target.value)}></textarea>
            <button onClick={() => sendMessage()} className="sendbtn">Contact us</button>
          </Col>
          <Col sm="10" md="6">
            <Container>
              <div className="map">
                <Map zoomLevel={17} />
              </div>
              <h5 className="h5" style={{ marginTop: 10 }}>Phone</h5>
              <p style={{ marginBottom: 0, paddingBottom: 0, marginTop: 15 }}>904-803-3394</p>
              <a href="mailto:aspiringkidsls@gmail.com">aspiringkidsls@gmail.com</a>
            </Container>
          </Col>
        </Row>
      </Container>
    </Styles>
  )
}


const Styles = styled.div`
  .headimg{
    margin-top: 20px;
    width: 100%;
    height: 300px;
    background: url(${tron});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
    color: white;
  }

  .active{
    color: rgba(360,360,360, 0.6);
  }

  .inputtxt{
    width: 100%;
    padding: 8px 15px;
    border: 1px solid #424242;
    border-radius: 25px;
    outline: none;
    margin-bottom: 20px;
  }

  .textarea{
    resize: none;
    height: 200px;
    width: 100%;
    border: 1px solid #424242;
    border-radius: 10px;
    outline: none;
    margin-bottom: 10px;
    padding: 8px 15px;
  }

  .sendbtn{
    color: white;
    background-color: #42ace1;
    padding: 5px 10px 5px 10px;
    width: 100%;
    border-radius: 20px;
    outline: none;

    &: hover{
      opacity: 0.7;
    }
  }

  .map{
    margin-top: 20px;
    width: 100%;
    height: 250px;
  }

`;