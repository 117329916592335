import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import GetReviews from '../components/GetReviews';

import tron from './../assets/img/4.jpg';

import ceo from './../assets/img/ceo.jpg';
import vimg from './../assets/img/6.jpg';
import simg from './../assets/img/15.jpg';

export default function AboutUs(){
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => scrollToTop(), 0.1*1000)
  }, []);

  const scrollToTop = () => {
    const elmnt = document.getElementById("top");
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  return(
    <Styles>
      <div id="top" style={{ position: 'absolute', top: -50, width: 1, height: 1 }} />
      <div className="headimg">
        <div className="overlay">
          <Container style={{ padding: '4%' }}>
            <h4 className="h2" style={{ margin: 0 }}>About Us</h4>
            <nav style={{ background: 'transparent', marginLeft: 0, paddingLeft: 0 }} aria-label="breadcrumb">
              <ol style={{ background: 'transparent', color: 'rgba(360,360,360, 0.6)' }} class="breadcrumb">
                <li class="breadcrumb-item"><Link style={{ color: 'white' }} to="/">Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ol>
            </nav>
          </Container>
        </div>
      </div>
      <center style={{ marginTop: 40, marginBottom: 35, color: '#42ace1' }}><h3 className="h3">About Us</h3></center>
      <div className="box">
        <Container>
          <p>Our learning center is conveniently located online, where all sessions include live instruction and
              learning for your child. Aspiring Kids Learning Society was originally located in the historic district of
              Springfield in Jacksonville, Florida, where there is a high need and demand for accessibility to
              educational resources for underserved students in the community. During the COVID-19 pandemic,
              we added an online learning platform to continue serving students and their families within the
              community. Expanding our services online also affords us the ability to reach students outside of the
              Jacksonville area. For students who learn best with in-person instruction, we will begin hosting
              in-person sessions again in the near future.
          </p>
          <p>
          We are proud to work with and serve children and families, from a range of socioeconomic statuses,
          and meet the students at their learning and skills levels. The Aspiring Kids Learning Society
          continues to grow and learn. We are excited for you to join our growing list of success stories, so
          get ready to join us on this educational journey! Aspiring Kids Learning Society is the place where
          children can gain confidence and feel their best while achieving academic success.
          </p>

          <p>
            Contact us today to begin your student’s journey with Aspiring Kids Learning Society and connect
            with us on social media to stay up-to-date on<br/>
            Facebook-<a href="https://www.facebook.com/AspiringKidsLearningSociety">Aspiring Kids Learning Society</a><br/>
            Instagram-<a href="https://www.instagram.com/aspiringkidsls/">aspiringkidsls</a><br/>
            Email: <a href="mailto:AspiringKidsLS@gmail.com">AspiringKidsLS@gmail.com</a><br/>
            Phone: <a href="tel:904-803-3394">904-803-3394</a>
          </p>
        </Container>
      </div>
      <Container style={{ display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 20, display: 'flex' }}>
        <img src={ceo} className="ceoimg" />
        <div className="biotext">
          <p style={{ paddingLeft: 20, paddingRight: 20, textAlign: 'center' }}>
            “[I] love helping children build character and improve in school. I have a passion for helping children
            who may be at a disadvantage due to their environment, lack of resources, and/or unique learning
            abilities.” - Mrs. Santrice L. Grayer, M.Ed.
          </p>
          <p>Mrs. Santrice L. Grayer M.Ed., a native of Jacksonville, FL. and product
            of Duval County Public Schools (graduate of Paxon School for Advanced Studies) has had the pleasure of teaching
            English Language Arts in grades K-12 for the past nine years. She takes pride in her experience with students from
            various socioeconomic levels and students with learning disabilities. Showing children how to develop a love for literacy,
            and teaching them literacy skills is what inspires her each and every day! Her passion for teaching is what inspired her to
            create her company Aspiring Kids Learning Society. Mrs. Grayer believes all children should gain confidence from learning
            and feel their BEST while learning! This belief is what motivates her to continue her own professional development and
            learning in the field of education. She most recently obtained a Master’s of Education degree from the University of North
            Florida, and is currently pursuing a Doctor of Education degree at Xavier University of Louisiana. This latest pursuit allows
            her to further study the needs of disadvantaged students in urban environments. Her research is focused on improving
            educational policy, curriculum, and learning instructional best practices for urban and multicultural learners. Recently,
            Mrs. Grayer earned the prestigious title of the 2021 Jax Image Awards finalist for Educational Excellence. Along with her
            life’s work in education, Mrs. Grayer is a proud wife, mother of two, dog mom, and member of Zeta Phi Beta Sorority, Inc.
          </p>
          <p style={{ textAlign: 'right', marginTop: 15, color: '#0275d8', fontFamily: 'sans-serif' }}>Santrice Grayer</p>
        </div>
      </Container>
      <Row style={{ color: 'white', background: '#0275d8' }}>
        <Col sm="11" md="5">
          <img src={vimg} style={{ width: '100%', maxHeight: 300, objectFit: 'cover' }} />
        </Col>
        <Col sm="11" md="6" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: "15px 5%" }}>
          <h3 className="h3">Our Vision</h3>
          <p className="textcontent">Our goal is to promote literacy and life skills throughout the various communities of Jacksonville, FL. and beyond. We pride ourselves in relationship building. Our learning center is conveniently located online where all sessions include LIVE instructions and learning for every child.</p>
        </Col>
      </Row>

      <Row style={{ color: 'white', background: '#42ace1', justifyContent: 'space-between' }}>
        <Col sm="11" md="7" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: "15px 5%" }}>
          <h3 className="h3">Our Story</h3>
          <p className="textcontent">Aspiring Kids was originally located in the historic district of Springfield in Jacksonville, Florida where there is a high need and demand for accessibility to educational resources for undeserved students in the community. However, during the COVID-19 pandemic the we added an online learning platform, to continue serving our students and their families within the community, while expanding our ability to reach students outside of the local area.</p>
        </Col>
        <Col sm="11" md="5">
          <img src={simg} style={{ width: '100%', minHeight: 200, maxHeight: 300, objectFit: 'cover' }} />
        </Col>
      </Row>

      <Container style={{ marginTop: 20, marginBottom: 20 }}>
        <GetReviews />
      </Container>

      <Row style={{ width: '100%', borderTop: "1px solid #dbdbdb", paddingTop: 20, justifyContent: 'center', marginBottom: 25 }}>
        <button onClick={() => history.push("/reviews")} className="learnbtn">Leave a review</button>
      </Row>
    </Styles>
  )
}


const Styles = styled.div`
  .headimg{
    margin-top: 20px;
    width: 100%;
    height: 300px;
    background: url(${tron});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
    color: white;
  }

  .active{
    color: rgba(360,360,360, 0.6);
  }

  .box{
    background-color: #42ace1;
    color: white;
    padding: 2% 10%;
  }

  .ceoimg{
    width: 150px;
    height: 150px;
    margin-right: -75px;
    margin-top: -35px;
    z-index: 10;
  }

  .biotext{
    width: 80%;
    border: 1px solid #dbdbdb;
    padding: 15px 10px 10px 85px;
  }

  @media screen and (max-width: 770px){
    .ceoimg{
      width: 100px;
      height: 100px;
      margin-right: -50px;
      margin-top: -25px;
      z-index: 10;
    }

    .biotext{
      width: 80%;
      border: 1px solid #dbdbdb;
      padding: 15px 20px 10px 65px;
    }
  }

  .learnbtn{
    min-width: 120px;
    color: white;
    background-color: #42ace1;
    border-radius: 20px;
    height: 35px;
    outline: none;

    &: hover{
      opacity: 0.7;
    }
  }

  a{
    color: white;
  }

`;