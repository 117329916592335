import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ClientNavBar from './components/ClientNavBar';
import ClientTopBar from './components/ClientTopBar';
import AllCourses from './screens/AllCourses';
import MessageSupport from './screens/MessageSupport';
import Settings from './screens/Settings';
import CourseDetails  from './screens/CourseDetails';
import BookedCourses from './screens/BookedCourses';

import StripeContainer from './components/StripeContainer';

export default function Client(){
  return(
    <Router>
      <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height: '100vh' }}>
        <ClientNavBar />
        <div style={{ width: '100%', overflowY: 'auto' }}>
          <ClientTopBar/>
          <Switch>
            <Route path="/" component={AllCourses} exact />
            <Route path="/course/:trainid" component={CourseDetails} />
            <Route path="/mine" component={BookedCourses} />
            <Route path="/support" component={MessageSupport} />
            <Route path="/settings" component={Settings} />
            <Route path="/stripe" component={StripeContainer} />
          </Switch>
        </div>
      </div>
    </Router>
  )
}