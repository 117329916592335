import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './screens/Home';
import Team from './screens/Team';
import Contact from './screens/Contact';
import Services from './screens/Services';
import AboutUs from './screens/AboutUs';
import Reviews from './screens/Reviews';
import AdminLogin from './screens/AdminLogin';
import PasswordRecovery from './screens/PasswordRecovery';

function Guest() {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Router>
        <NavBar />
        <div style={{ paddingTop: '7.5vh' }}>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/team" component={Team} />
            <Route path="/contact" component={Contact} />
            <Route path="/services" component={Services} />
            <Route path="/aboutus" component={AboutUs} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/admin" component={AdminLogin} />
            <Route exact path="/recover-password/:id/:token" component={PasswordRecovery} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default Guest;
