import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { Radio } from 'antd';
import styled from 'styled-components';
import axios from 'axios';

import { getFromStorage } from '../components/Storage';
import { url } from '../components/Url';

import left from "./../assets/art/left.png";
import right from "./../assets/art/right.png";
import clock from "./../assets/art/gclock.png";
import svg from "./../assets/art/404.svg";

export default function BookedCourses(){
  const userid = getFromStorage("user").userid;
  const history = useHistory();
  const [trainings, setTrainings] = useState([]);
  const [last, setLast] = useState(200000);

  useEffect(() => {
    getTrainings(last)
  }, []);

  const getTrainings = (lid) => {
    axios.post(url()+"mine.php", { userid: userid, last: lid })
    .then(res => {
      if(res.data.stat == 1){
        const length = res.data.trainings.length - 1;
        if(length >= 0){
          setTrainings(res.data.trainings);
          setLast(res.data.trainings[length].id);
        }else{
          setTrainings([]);
          setLast(20000);
        }
      }
    })
  }

  const goBack = () => {
    const l = last + 6;
    getTrainings(l)
  }

  const goFront = () => {
    const l = last;
    getTrainings(l)
  }

  const viewParser = () => {
    if(trainings.length > 5){
      return(
        <>
        <div className="trainigcontainer">
          {trainings.map(i => {
            return(
              <div className="training">
                <img loading="lazy" src={url()+i.pic} className="tpic" />
                <div className="tinfo">
                  <div style={{ borderBottom: '1px solid #dbdbdb', padding: 10 }}>
                    <h6 className="h6 oneline">{i.title}</h6>
                    <p className="oneline" style={{ fontSize: 13 }}>{i.desc}</p>
                  </div>
                  <div style={{ padding: 4, display: 'flex', flexDirection: 'row', borderBottom: '1px solid #dbdbdb', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <img loading="lazy" src={url()+i.photo} style={{ width: 25, height: 25, objectFit: 'cover', borderRadius: '50%' }} />
                    <p className="oneline">{i.trainer}</p>
                    <h5 className='h5 oneline'>${i.price}</h5>
                  </div>
                  <div style={{ padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    {i.mine == 0 && <button onClick={() => history.push(`/course/${i.id}`)} className="bookbtn">Book Now</button>}
                    {i.mine == 1 && <button onClick={() => history.push(`/course/${i.id}`)} className="bookbtn">View Training</button>}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 14, color: '#bbbbbb' }}>
                      <img loading="lazy" src={clock} style={{ width: 25, height: 25, marginRight: 5 }} />
                      <p className="oneline">1 hour</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Row className="footrow">
          <button onClick={() => goBack()} className="ctlbutton"><img src={left} style={{ width: 20, height: 20 }} /></button>
          <button onClick={() => goFront()} className="ctlbutton"><img src={right} style={{ width: 20, height: 20 }} /></button>
        </Row>
        </>
      )
    }else if(trainings.length < 5 && trainings.length > 0){
      return(
        <>
        <div className="trainigcontainer">
          {trainings.map(i => {
            return(
              <div className="training">
                <img loading="lazy" src={url()+i.pic} className="tpic" />
                <div className="tinfo">
                  <div style={{ borderBottom: '1px solid #dbdbdb', padding: 10 }}>
                    <h6 className="h6 oneline">{i.title}</h6>
                    <p className="oneline" style={{ fontSize: 13 }}>{i.desc}</p>
                  </div>
                  <div style={{ padding: 4, display: 'flex', flexDirection: 'row', borderBottom: '1px solid #dbdbdb', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    <img loading="lazy" src={url()+i.photo} style={{ width: 25, height: 25, objectFit: 'cover', borderRadius: '50%' }} />
                    <p className="oneline">{i.trainer}</p>
                    <h5 className='h5 oneline'>${i.price}</h5>
                  </div>
                  <div style={{ padding: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    {i.mine == 0 && <button onClick={() => history.push(`/course/${i.id}`)} className="bookbtn">Book Now</button>}
                    {i.mine == 1 && <button onClick={() => history.push(`/course/${i.id}`)} className="bookbtn">View Training</button>}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 14, color: '#bbbbbb' }}>
                      <img loading="lazy" src={clock} style={{ width: 25, height: 25, marginRight: 5 }} />
                      <p className="oneline">1 hour</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <Row className="footrow">
          <button onClick={() => goBack()} className="ctlbutton"><img src={left} style={{ width: 20, height: 20 }} /></button>
          <button disabled onClick={() => goFront()} className="ctlbutton"><img src={right} style={{ width: 20, height: 20 }} /></button>
        </Row>
        </>
      )
    }else if(trainings.length == 0){
      return(
        <>
        <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 30, paddingBottom: 30 }}>
          <img src={svg} style={{ width: '40%' }} />
          <h4 className="h4">No Trainings</h4>
        </Container>
        <Row className="footrow">
          <button onClick={() => goBack()} className="ctlbutton"><img src={left} style={{ width: 20, height: 20 }} /></button>
          <button onClick={() => goFront()} className="ctlbutton"><img src={right} style={{ width: 20, height: 20 }} /></button>
        </Row>
        </>
      )
    }
  }


  return(
    <Styles style={{ margin: 0, padding: 0, paddingLeft: 30, paddingRight: 30 }}>
      <Container>

        {viewParser()}
      </Container>
    </Styles>
  )
}


const Styles = styled.div`
  .training{
    width: 250px;
    height: 300px;
    margin: 10px;
    box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.1);
    display: inline-block;
  }

  .tpic{
    width: 100%;
    height: 170px;
    object-fit: cover;
  }

  @media screen and (max-width: 770px){
    .training{
      width: 100%;
      height: 300px;
    }
  }

  .tinfo{
    width: 100%;
    height: 130px;
  }

  .oneline{
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bookbtn{
    padding: 3px 10px;
    background-color: orange;
    color: white;
    border-radius: 20px;
    font-size: 13px;
    outline: none;

    &: hover{
      opacity: 0.8;
    }
  }

  .footrow{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .ctlbutton{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    outline: none;

    &: hover{
      background-color: rgba(0,0,0,0.4);
    }
  }

`;