import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { Alert } from 'rsuite';
import styled from 'styled-components';
import axios from 'axios';

import GetAllReviews from '../components/GetAllReviews';
import { url } from './../components/Url';

import tron from './../assets/img/6.jpg';

import vimg from './../assets/img/7.jpg';
import simg from './../assets/img/13.jpg';

export default function Reviews(){
  const history = useHistory();
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [stars, setStars] = useState(4);
  const [street, setStreet] = useState('');
  const [review, setReview] = useState('');

  useEffect(() => {
    setTimeout(() => scrollToTop(), 0.1*1000)
  }, []);

  const scrollToTop = () => {
    const elmnt = document.getElementById("top");
    elmnt.scrollIntoView({ behavior: "smooth" });
  }

  const addReview = () => {
    if(!fname || !lname || !email){
      return Alert.error("Please fill all fields");
    }
    const data = { fname, lname, email, street, review, stars };
    axios.post(url()+"addreview.php", data)
    .then(res => {
      if(res.data.stat == 1){
        Alert.success('Review Submitted');
        setFname('');
        setLname('');
        setEmail('');
        setStreet('');
        setReview('');
      }else if(res.data.stat == -1){
        Alert.error("You already submitted a review");
      }else{
        Alert.error("Error processing request");
        alert(JSON.stringify(res.data))
      }
    })
  }

  return(
    <Styles>
      <div id="top" style={{ position: 'absolute', top: -50, width: 1, height: 1 }} />
      <div className="headimg">
        <div className="overlay">
          <Container style={{ padding: '4%' }}>
            <h4 className="h2" style={{ margin: 0 }}>Reviews</h4>
            <nav style={{ background: 'transparent', marginLeft: 0, paddingLeft: 0 }} aria-label="breadcrumb">
              <ol style={{ background: 'transparent', color: 'rgba(360,360,360, 0.6)' }} class="breadcrumb">
                <li class="breadcrumb-item"><Link style={{ color: 'white' }} to="/">Home</Link></li>
                <li class="breadcrumb-item active" aria-current="page">Reviews</li>
              </ol>
            </nav>
          </Container>
        </div>
      </div>

<Row style={{ marginTop: 50, borderBottom: '1px solid #dbdbdb' }}>
      <Col sm="12" md="6" className="revs" style={{ margin: 0, padding: 0, paddingLeft: '5%', display: 'flex', justifyContent: 'center' }}>
        <GetAllReviews />
      </Col>

      <Col sm="12" md="6" style={{ marginBottom: 10, paddingBottom: 20 }}>
        <center style={{ marginBottom: 35, color: '#42ace1' }}><h5 className="h3">Leave a review</h5></center>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="inrow">
            <input onChange={e => setFname(e.target.value)} value={fname} type="text" className="inputtxt" placeholder="Parent's first name" />
            <input value={lname} onChange={e => setLname(e.target.value)} type="text" className="inputtxt" placeholder="Parent's last name" />
          </div>
          <div className="inrow">
            <input value={email} onChange={e => setEmail(e.target.value)} type="text" className="inputtxt" placeholder="Email address" />
            <input value={street} onChange={e => setStreet(e.target.value)} type="text" className="inputtxt" placeholder="Street address" />
          </div>
          <textarea placeholder="Write your review" value={review} className="textarea" onChange={e => setReview(e.target.value)}></textarea>
          <button onClick={() => addReview()} className="sendbtn">Leave a review now</button>
        </div>
      </Col>

</Row>
      
      <Row style={{ marginBottom: 10, paddingBottom: 0, borderBottom: '1px solid #dbdbdb' }}>
        <Col sm="11" md="5">
          <img src={vimg} style={{ width: '100%', maxHeight: 300, objectFit: 'cover' }} />
        </Col>
        <Col sm="11" md="6" className="padp" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: "15px 5%", marginBottom: 20 }}>
          <h3 className="h3" style={{ color: '#42ace1' }}>Certified Professionals</h3>
          <p className="textcontent">Our staff is comprised of educators who have K-6 teaching certifications, 6-12 ELA teaching
            certifications, and/or 6-12 Math teaching certifications. Click <Link to="/team">here</Link> to meet our team.
          </p>
          <button onClick={() => history.push("/team")} className="learnbtn">Learn More</button>
        </Col>
      </Row>

      <Row style={{ justifyContent: 'space-between' }}>
        <Col sm="11" md="7" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: "15px 5%", marginBottom: 20 }}>
          <h3 className="h3" style={{ color: '#42ace1' }}>Variety of K-12 Support</h3>
          <p className="textcontent">Aspiring Kids Learning Society exists to help your student succeed. We offer a variety of K-12
            educational support, in reading, writing, and mathematics, designed to meet each student at their
            level and help them achieve their academic goals. Click <Link to="/services">here</Link> to look over our K-12
            support services before scheduling your free consultation.
          </p>
          <button onClick={() => history.push("/services")} className="learnbtn">Learn More</button>
        </Col>
        <Col sm="11" md="5">
          <img src={simg} style={{ width: '100%', minHeight: 250, maxHeight: 300, objectFit: 'cover', marginTop: -10 }} />
        </Col>
      </Row>
    </Styles>
  )
}


const Styles = styled.div`
  .headimg{
    margin-top: 20px;
    width: 100%;
    height: 300px;
    background: url(${tron});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlay{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0,0,0,0.3);
    color: white;
  }

  .active{
    color: rgba(360,360,360, 0.6);
  }

  .inrow{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .inputtxt{
    width: 100%;
    padding: 8px 15px;
    border: 1px solid #424242;
    border-radius: 25px;
    outline: none;
    margin-bottom: 20px;
  }

  .textarea{
    resize: none;
    height: 200px;
    width: 75%;
    border: 1px solid #424242;
    border-radius: 10px;
    outline: none;
    margin-bottom: 10px;
    padding: 8px 15px;
  }

  .sendbtn{
    color: white;
    background-color: #42ace1;
    padding: 5px 10px 5px 10px;
    width: 35%;
    border-radius: 20px;
    outline: none;

    &: hover{
      opacity: 0.7;
    }
  }

  .learnbtn{
    width: 130px;
    color: white;
    background-color: #bc023a;
    padding: 7px;
    border-radius: 20px;
    outline: none;
  }

  .revs{
    padding-top: 65px;
  }

  @media screen and (max-width: 770px){
    .inrow{
      width: 95%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .inputtxt{
      width: 100%;
      margin-bottom: 10px;
    }

    .textarea{
      width: 95%;
    }
  
    .sendbtn{
      width: 95%;
    }

    .padp{
      padding-left: 5%;
    }

    .revs{
      padding-top: 0px;
    }
  }

`;