import { Link, useHistory } from 'react-router-dom';
import styled from "styled-components";
import { Container, Col, Row } from 'react-bootstrap';

import chat from './../assets/art/chat.png';
import facebook from './../assets/art/facebook.png';
import insta from './../assets/art/insta.png';

export default function Footer(){
  const history = useHistory();
  return(
    <Styles>
      <Row style={{ display: 'flex', justifyContent: 'space-evenly', backgroundColor: '#42ace1', color: 'white', paddingTop: 7, alignItems: 'center' }}>
        <Col sm="8" md="6">
          <Container style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <img src={chat} style={{ width: 70, height: 70 }} />
            <div style={{ marginLeft: 10 }}>
              <h3 className="h3">Live Online Services</h3>
              <p style={{ fontSize: 14, marginTop: 10 }}>
                Live, online lessons provide the highest quality of educational services, all from the convenience of
                your own home. These sessions are offered on a set schedule, however, depending on availability,
                drop-in sessions may be arranged. Click <Link to="/services">here</Link> to peruse our online services
                before scheduling your free consultation.
              </p>
            </div>
          </Container>
        </Col>
        <Col sm="3">
          <Container style={{ padding: 15 }}>
            <button onClick={() => history.push("/services")} className="startbtn">Get Started</button>
          </Container>
        </Col>
      </Row>

      <Row style={{ backgroundColor: 'rgba(0, 0, 102, 0.9)', color: 'white', width: '100%', margin: 0, paddingTop: 10, paddingBottom: 10, display: 'flex', justifyContent: 'space-evenly' }}>
        <Col className="footercontent" sm="10" md="3">
          <h5 className="h5">Phone</h5>
          <div className="linkconainer">
            <p className="footlink">904-803-3394</p>
            <p className="footlink">aspiringkidsls@gmail.com</p>
            <div className="d-none d-md-flex" style={{ flexDirection: 'row' }}>
              <a href="https://www.facebook.com/AspiringKidsLearningSociety" target="blan"><img src={facebook} className="sicon" /></a>
              <a href="https://www.instagram.com/aspiringkidsls/" target="blan"><img src={insta} className="sicon" /></a>
            </div>
          </div>
        </Col>
        <Col className="footercontent" sm="10" md="3">
          <h5 className="h5">Address</h5>
          <div className="linkconainer">
            <p className="footlink">6001 Argyle Boulevard</p>
            <p className="footlink">Suite 21, PMB 275</p>
            <p className="footlink">Jacksonville, Florida 32244</p>
          </div>
        </Col>
        <Col className="footercontent" sm="10" md="3">
          <h5 className="h5">Links</h5>
          <div className="linkconainer">
            <Link to="/aboutus" className="footlink">About us</Link>
            <Link to="/team" className="footlink">Meet the team</Link>
            <Link to="/services" className="footlink">Services</Link>
          </div>
        </Col>
        <Col className="footercontent" sm="10" md="3">
          <h5 className="h5">Opening hours</h5>
          <div className="linkconainer">
            <p to="#" className="footlink">Monday to Friday</p>
            <p to="#" className="footlink">10 AM - 8 PM</p>
            <p to="#" className="footlink">Saturday</p>
            <p to="#" className="footlink">10 AM - 2 PM</p>
          </div>
        </Col>
      </Row>

      <div className="d-flex d-md-none mob" style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 102, 0.9)', border: 0 }}>
        <a href="https://www.facebook.com/AspiringKidsLearningSociety" target="blan"><img src={facebook} className="sicon" /></a>
        <a href="https://www.instagram.com/aspiringkidsls/" target="blan"><img src={insta} className="sicon" /></a>
      </div>

      <Row style={{ height: 50, backgroundColor: '#00004c', color: 'white', width: '100%', margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', borderTop: "1px solid #444444" }}>
        <p style={{ margin: 0, padding: 0, fontSize: 13 }}>Terms & Conditions | Privacy Policy @{new Date(Date.now()).getFullYear()} All rights reserved</p>
      </Row>
    </Styles>
  )
}

const Styles = styled.div`
  .startbtn{
    color: #42ace1;
    background-color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    outline: none;

    &: hover{
      opacity: 0.7;
    }
  }

  .footercontent h5{
    margin-bottom: 10px;
  }

  .linkconainer{
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .footlink{
    color: rgba(360,360,360,0.9);
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .sicon{
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background-color: white;
    object-fit: cover;
    padding: 4px;
    border-radius: 50%;
  }

  .mob .sicon{
    width: 50px;
    height: 50px;
  }

`;